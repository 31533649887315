import { StorageKey } from '@constants';
import { useEffect } from 'react';
import { generatePath, useLocation, useNavigate, useParams } from 'react-router-dom';
import CanvasProvider from 'src/blueprint/pages/editor/EditorContext';
import AuthForm from 'src/components/AuthForm';
import ModalWindow from 'src/components/ModalWindow';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux';
import { useLazyGetReportBySlugQuery, useSharedReportLoginMutation } from 'src/redux/api';
import { selectSharedAuth, setSharedAuth } from 'src/redux/features/auth/authSlice';
import {
    selectActiveReport,
    selectActiveReportPage,
    setActiveReportPageId
} from 'src/redux/features/blueprint/bluePrintSlice';
import { Root } from './Root';
import { PathNames } from 'src/router/router';
import { ClientIdParams, ReportSlugParams, ViewSlugParams } from '@router';
import { Box } from '@chakra-ui/react';

const PASSWORDLESS_REPORTS = ['sample', 'sample-zirconads'];

const SharedReport = () => {
    const location = useLocation();
    const params = useParams<ReportSlugParams & ViewSlugParams & ClientIdParams>();
    const auth = useAppSelector(selectSharedAuth);
    const [sharedLogin] = useSharedReportLoginMutation();
    const [getSharedReportBySlug] = useLazyGetReportBySlugQuery();
    const dispatch = useAppDispatch();
    const activeReport = useAppSelector(selectActiveReport);
    const activePage = useAppSelector(selectActiveReportPage)
    const navigate = useNavigate();

    const isAuthenticated =
        auth?.isAuthenticated || PASSWORDLESS_REPORTS.includes(params.reportSlug ?? '');

    const handleAuthFormSubmit = (password: string) => {
        dispatch(setSharedAuth({ isAuthenticated: false, loading: true }));
        sharedLogin({
            clientId: +params.clientId!,
            slug: params.reportSlug!,
            password
        })
            .unwrap()
            .then((response) => {
                dispatch(
                    setSharedAuth({
                        isAuthenticated: true,
                        loading: false,
                        reportSlugToken: response.accessToken,
                        reportSlug: params.reportSlug,
                        reportTokens: {
                            ...auth.reportTokens,
                            [`${params.clientId}::${params.reportSlug!}`]:
                                response.accessToken
                        }
                    })
                );
                const localStorageReportTokens = localStorage.getItem(
                    StorageKey.TOKEN_BY_REPORT_NAME
                );
                const reportTokens = JSON.parse(localStorageReportTokens ?? '{}');
                localStorage.setItem(
                    StorageKey.TOKEN_BY_REPORT_NAME,
                    JSON.stringify({
                        ...reportTokens,
                        [`${params.clientId}::${params.reportSlug!}`]:
                            response.accessToken
                    })
                );
            })
            .catch(() => {
                dispatch(
                    setSharedAuth({
                        isAuthenticated: false,
                        loading: false,
                        error: 'Nesprávne heslo'
                    })
                );
            });
    };

    useEffect(() => {
        const run = async () => {
            if (isAuthenticated) {
                if (params.reportSlug === activeReport?.slug) {
                    const activePage = activeReport?.pages.find(
                        (page) => page.isActive && page.slug === params.viewSlug
                    );

                    if (activePage) {
                        dispatch(setActiveReportPageId(activePage.id));
                    } else {
                        const firstAnyActivePage = activeReport?.pages.find((page) => page.isActive);
                        if (firstAnyActivePage) {
                            dispatch(setActiveReportPageId(firstAnyActivePage.id));
                        }
                    }
                } else {
                    try {
                        const response = await getSharedReportBySlug({
                            clientId: +params.clientId!,
                            slug: params.reportSlug,
                            isSharedReport: true
                        }).unwrap();

                        const firstAnyActivePage = response.pages.find((page: any) => page.isActive);

                        if (!params.viewSlug) {
                            navigate(`${location.pathname}/${firstAnyActivePage.slug}`);
                        }
                    } catch (error: any) {
                        if ('originalStatus' in error && error.originalStatus === 401) {
                            dispatch(
                                setSharedAuth({
                                    isAuthenticated: false,
                                    loading: false
                                })
                            );
                        }
                    }
                }
            }
        };

        run();
    }, [auth.isAuthenticated, params.reportSlug, params.viewSlug, activeReport]);

    return (
        <Box overflowX="auto">
            {isAuthenticated && (
                <CanvasProvider
                    useEditorReport={false}
                    useSharedReportAPI={true}
                    reportBasePathName={generatePath(PathNames.BASE_SHARED_REPORT, {
                        clientId: params.clientId!
                    })}

            height={activePage?.layout?.pageHeight}
            width={activePage?.layout?.pageWidth}
                >
                    <Root />
                </CanvasProvider>
            )}
            {!isAuthenticated && (
                <ModalWindow title="Zadajte heslo" withSeparator={false} isOpen={true}>
                    <AuthForm
                        onSubmit={(password: string) => handleAuthFormSubmit(password)}
                        isLoading={auth.loading}
                        errorMessage={auth.error}
                    />
                </ModalWindow>
            )}
        </Box>
    );
};

export default SharedReport;
