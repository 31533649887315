import { Flex, Text } from '@chakra-ui/react';
import { DataComponent } from 'common/types';
import { styleValidator } from 'common/validators';
import { useAppSelector } from 'src/hooks/redux';
import {
    selectComponent,
    selectComponentData
} from 'src/redux/features/blueprint/bluePrintSlice';
import { GenericTable } from 'src/templates/blueprint/organisms/table/GenericTable';
import { z } from 'zod';
import GoCard from '../GoCard';

type TabbleProperties = z.infer<typeof styleValidator.table>['properties'];

const Table = ({ properties, id }: { properties: TabbleProperties; id: string }) => {
    const componentData = useAppSelector((state) => selectComponentData(state, id));
    const component = useAppSelector((state) =>
        selectComponent(state, id)
    ) as DataComponent;

    const renderContent = () => {
        if (!component.dimensions?.length || !component.metrics?.length) {
            return (
                <Flex justifyContent="center" alignItems="center" w="100%">
                    <Text color="inherit">
                        Select at least one dimension and one metric to display the table.
                    </Text>
                </Flex>
            );
        }

        if (componentData === undefined) {
            return <Flex justifyContent="center" alignItems="center" w="100%" />;
        }

        if (componentData?.error || componentData === undefined) {
            return (
                <Flex justifyContent="center" alignItems="center" w="100%">
                    <Text>
                        There was an error fetching the data. Please try again later.
                    </Text>
                </Flex>
            );
        }

        if (componentData) {
            return (
                <GenericTable
                    data={componentData ?? []}
                    properties={properties}
                    id={id}
                    h={component.h}
                />
            );
        }
    };

    return (
        <GoCard cardStyle={properties?.cardStyle} w="100%" h="100%">
            {renderContent()}
        </GoCard>
    );
};

export default Table;
