import { Box, Flex, Text } from '@chakra-ui/react';
import { useSize } from '@hooks';
import { ChartData, ComponentUI } from '@types';
import { ColumnFormatType, ComponentType } from 'common/enums';
import React, { CSSProperties } from 'react';
import { Cell, Legend, LegendProps, Pie, PieChart, Surface, Tooltip } from 'recharts';
import { useAppSelector } from 'src/hooks/redux';
import {
    selectComponent,
    selectComponentData
} from 'src/redux/features/blueprint/bluePrintSlice';
import { LinearGradient, LinearGradientStop } from 'src/types/UISchema';
import { formatGenericValue, isPuppeteer } from 'src/utils';
import { v4 as uuidv4 } from 'uuid';
import DonutChartContentLoader from '../contentLoaders/DonutChart';
import { useDefaultData } from '../defaultData';
import { DataComponent } from 'common/types';
import { useGradientPalletColor } from './BarCharts/utils';
import { renderTooltipContent } from './BarCharts/RenderTooltipContent';
import { renderPieTooltipContent } from '../RenderPieTooltip';
import RenderStatusContent from '../RenderContent';

const RADIAN = Math.PI / 180;
const OUTER_RADIUS = 40;

type renderCustomizedLabelProps = {
    cx: number;
    cy: number;
    midAngle: number;
    innerRadius: number;
    outerRadius: number;
    percent: number;
    scale: number;
};
const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent
}: renderCustomizedLabelProps) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
        <text
            x={x}
            y={y}
            fill="white"
            textAnchor={x > cx ? 'start' : 'end'}
            dominantBaseline="central"
            fontSize={8 * ((outerRadius - innerRadius) / 100 + 0.5)}
            fontWeight={'bold'}
        >
            {`${(percent * 100).toFixed(0)}%`}
        </text>
    );
};

interface ChartDataProps extends ChartData {
    scale?: number;
    showLabel?: boolean;
}

interface Props {
    data: ChartDataProps;
    properties: any;
}

interface RenderLegendProps extends LegendProps {
    itemsAlign: 'left' | 'right' | 'mirror';
    payload: { color: string; value: string }[];
    symbolFillColors: CSSProperties['color'][];
    symbolTextColors: CSSProperties['color'][];
    legendSymbols: ('square' | 'circle')[];
    legendStyle?: CSSProperties;
    donutChartRatio: number;
}

const renderLegend = (props: RenderLegendProps) => {
    const SIZE = 32 * props.donutChartRatio;
    const svgStyle = {
        display: 'inline-block',
        verticalAlign: 'middle',
        marginRight: props.itemsAlign === 'left' ? 10 : 0,
        marginLeft: props.itemsAlign === 'left' ? 0 : 10
    };
    const itemStyle = {
        justifyContent: props.itemsAlign === 'left' ? 'flex-start' : 'flex-end',
        display: 'flex',
        alignItems: 'center'
    };

    const LegendSymbol = (style: { color: string; type: string }) => {
        switch (style.type) {
            case 'circle':
                return (
                    <path
                        stroke="none"
                        fill={style.color}
                        d={`M0,${SIZE / 2}a${SIZE / 2},${SIZE / 2} 0 1,0 ${SIZE},0a${
                            SIZE / 2
                        },${SIZE / 2} 0 1,0 ${-SIZE},0`}
                        className="recharts-legend-icon"
                    />
                );
            case 'square':
                return (
                    <path
                        stroke="none"
                        fill={style.color}
                        d={`M0,${SIZE / 8}h${SIZE}v${SIZE}h${-SIZE}z`}
                        className="recharts-legend-icon"
                    />
                );
        }
    };

    const payloadOverflow = props.payload.length > 7;
    let payload = payloadOverflow ? props.payload.slice(0, 7) : props.payload;
    if (payloadOverflow) {
        payload.push({
            color: 'gray',
            value: `+${props.payload.length - 7} more`
        });
    }

    return (
        <ul
            style={{
                ...props.legendStyle,
                display: props?.layout === 'vertical' ? 'flex' : 'grid',
                gridTemplateColumns:
                    props?.layout === 'vertical' ? undefined : 'auto auto auto',
                flexDirection: props?.layout === 'vertical' ? 'column' : 'row',
                justifyContent: props?.align ?? 'center',
                gap: 2 * props.donutChartRatio
            }}
        >
            {payload.map((entry: { color: string; value: string }, index: number) => {
                return (
                    <li key={`item-${index}`} style={itemStyle}>
                        {props.itemsAlign === 'left' ||
                        (props.itemsAlign === 'mirror' && index % 2 === 1) ? (
                            <>
                                <Surface
                                    width={5 * props.donutChartRatio}
                                    height={5 * props.donutChartRatio}
                                    viewBox={{
                                        x: 0,
                                        y: 0,
                                        width: SIZE,
                                        height: SIZE
                                    }}
                                    style={{
                                        ...svgStyle,
                                        minWidth: 5 * props.donutChartRatio,
                                        minHeight: 5 * props.donutChartRatio,
                                        marginRight: 10,
                                        marginLeft: props.itemsAlign === 'mirror' ? 0 : 10
                                    }}
                                >
                                    <LegendSymbol
                                        color={props.symbolFillColors?.[index] ?? 'black'}
                                        type={props.legendSymbols?.[index] ?? 'square'}
                                    />
                                </Surface>
                                <span
                                    style={{
                                        overflow: 'hidden',
                                        display: '-webkit-box',
                                        WebkitLineClamp: 2,
                                        WebkitBoxOrient: 'vertical',
                                        textOverflow: 'ellipsis',
                                        color: props.symbolTextColors?.[index] ?? 'black'
                                    }}
                                    title={entry.value?.toString()}
                                >
                                    {entry.value}
                                </span>
                            </>
                        ) : (
                            <>
                                <span
                                    style={{
                                        overflow: 'hidden',
                                        display: '-webkit-box',
                                        WebkitLineClamp: 2,
                                        WebkitBoxOrient: 'vertical',
                                        textOverflow: 'ellipsis',
                                        color: props.symbolTextColors?.[index] ?? 'black'
                                    }}
                                    title={entry.value?.toString()}
                                >
                                    {entry.value}
                                </span>
                                <Surface
                                    width={5 * props.donutChartRatio}
                                    height={5 * props.donutChartRatio}
                                    viewBox={{
                                        x: 0,
                                        y: 0,
                                        width: SIZE,
                                        height: SIZE
                                    }}
                                    style={{
                                        ...svgStyle,
                                        minWidth: 5 * props.donutChartRatio,
                                        minHeight: 5 * props.donutChartRatio
                                    }}
                                >
                                    <LegendSymbol
                                        color={props.symbolFillColors?.[index] ?? 'black'}
                                        type={props.legendSymbols?.[index] ?? 'square'}
                                    />
                                </Surface>
                            </>
                        )}
                    </li>
                );
            })}
        </ul>
    );
};

const ZirconPieChart = ({ properties, id }: ComponentUI) => {
    const target = React.useRef(null);
    const size = useSize(target);
    const donutChartRatioX = size?.width ? size?.width / 100 : 1;
    const donutChartRatioY = size?.height ? size?.height / 100 : 1;
    const donutChartRatio =
        donutChartRatioX < donutChartRatioY ? donutChartRatioX : donutChartRatioY;
    const componentData = useAppSelector((state) => selectComponentData(state, id));
    const component = useAppSelector((state) =>
        selectComponent(state, id)
    ) as DataComponent;

    let data: any = componentData && componentData?.length > 0 ? componentData : [];

    if (properties?.component?.legend?.labelValueFormat === 'ABSOLUTE') {
        // no change
    } else {
        //  Change the value to percentage
        const total = data?.reduce(
            (acc: number, curr: { name: string; value: string }) => acc + +curr.value,
            0
        );
        data = data?.map((item: { name: string; value: string | number }) => ({
            ...item,
            value: ((+item.value / total) * 100).toFixed(2)
        }));
    }
    const scale = data?.scale || 0.8;
    const donutData = data?.map((item: { name: string; value: string | number }) => ({
        name: item.name,
        value: +item.value
    }));

    const fieldConfigs = data?.length > 0 ? data[0]?.fieldConfigs : {};

    const layout = properties?.component?.legend?.layout || 'vertical';
    const itemsAlign = properties?.component?.legend?.itemsAlign || 'right';
    const align = properties?.component?.legend?.align || 'center';
    const verticalAlign = properties?.component?.legend?.verticalAlign || 'middle';

    const wrapperStyleWidth = layout === 'vertical' ? '35%' : '100%';

    let textProps = {
        x: (size?.width ?? 500) / 2,
        textAnchor: 'middle'
    };

    if (properties?.labelStyle?.textAlign === 'left') {
        textProps = {
            ...textProps,
            x: 0,
            textAnchor: 'start'
        };
    }

    if (properties?.labelStyle?.textAlign === 'right') {
        textProps = {
            ...textProps,
            x: size?.width ?? 500,
            textAnchor: 'end'
        };
    }

    return (
        <Box ref={target} style={{ width: '100%', position: 'relative', height: '100%' }}>
            <RenderStatusContent
                style={{
                    height: size?.height,
                    width: size?.width,
                    ...properties.cardStyle
                }}
                isLoading={componentData === undefined}
                isEmpty={!component.dimensions?.length || !component.metrics?.length}
                isError={componentData?.error}
                isNoData={donutData.length === 0}
                emptyText="Select at least one dimension and one metric to display the pie chart."
            >
                {donutData?.length > 0 && (
                    <PieChart
                        style={properties.cardStyle}
                        height={size?.height}
                        width={size?.width}
                    >
                        {properties?.component?.label?.length > 0 && (
                            <text
                                x={textProps.x}
                                y={10}
                                textAnchor={textProps.textAnchor}
                                dominantBaseline="central"
                            >
                                <tspan
                                    style={{
                                        ...properties?.labelStyle,
                                        display: 'block',
                                        fill: properties?.labelStyle?.color ?? 'black'
                                    }}
                                >
                                    {properties?.component?.label}
                                </tspan>
                            </text>
                        )}
                        <defs>
                            {properties?.component?.linearGradients?.map(
                                (gradient: LinearGradient, index: number) => (
                                    <linearGradient
                                        key={uuidv4()}
                                        id={`${index.toString()}-${id}`}
                                        x1={gradient.x1}
                                        y1={gradient.y1}
                                        x2={gradient.x2}
                                        y2={gradient.y2}
                                    >
                                        {gradient.stops.map(
                                            (stop: LinearGradientStop) => (
                                                <stop
                                                    key={uuidv4()}
                                                    offset={stop.offset}
                                                    stopColor={stop.stopColor}
                                                    stopOpacity={stop.stopOpacity}
                                                />
                                            )
                                        )}
                                    </linearGradient>
                                )
                            )}
                        </defs>
                        <Pie
                            animationBegin={isPuppeteer ? 0 : 500}
                            stroke="none"
                            data={donutData}
                            cx="50%"
                            cy="50%"
                            labelLine={false}
                            label={(chartProps) => {
                                if (properties.component?.showInnerLabels) {
                                    return renderCustomizedLabel({
                                        ...chartProps,
                                        scale: scale
                                    });
                                } else {
                                    return <></>;
                                }
                            }}
                            outerRadius={OUTER_RADIUS * donutChartRatio}
                            fill="#8884d8"
                            dataKey="value"
                        >
                            {data?.map((_entry: any, index: number) => {
                                const { fill } = useGradientPalletColor(
                                    properties,
                                    index,
                                    id
                                );
                                return (
                                    <Cell
                                        style={{ outline: 'none' }}
                                        key={`cell-${index}`}
                                        fill={fill}
                                    />
                                );
                            })}
                        </Pie>
                        <Tooltip
                            formatter={(value) => {
                                return formatGenericValue(
                                    +value,
                                    ColumnFormatType.PERCENTAGE,
                                    data?.format
                                );
                            }}
                            content={(payload: any) =>
                                renderPieTooltipContent({
                                    ...payload,
                                    fieldConfigs: fieldConfigs,
                                    properties
                                })
                            }
                        />
                        <Legend
                            layout={layout}
                            verticalAlign={verticalAlign}
                            align={align}
                            wrapperStyle={{
                                fontSize: 4 * donutChartRatio,
                                width: wrapperStyleWidth
                            }}
                            content={(props) =>
                                renderLegend({
                                    ...props,
                                    itemsAlign,
                                    legendSymbols: properties?.component?.linearGradients
                                        ? properties?.component?.linearGradients?.map(
                                              (gradient: LinearGradient) =>
                                                  gradient.legendSymbol
                                          )
                                        : Array(data?.length).fill(
                                              properties?.component?.legend?.symbol
                                          ),
                                    symbolFillColors: properties?.component
                                        ?.linearGradients
                                        ? properties?.component?.linearGradients?.map(
                                              (gradient: LinearGradient) =>
                                                  gradient.legendSymbolFillColor
                                          )
                                        : properties?.component?.pallet,
                                    symbolTextColors: properties?.component
                                        ?.linearGradients
                                        ? properties?.component?.linearGradients?.map(
                                              (gradient: LinearGradient) =>
                                                  gradient.legendSymbolTextColor
                                          )
                                        : properties?.component?.pallet,
                                    legendStyle: properties?.legendStyle,
                                    donutChartRatio: donutChartRatio
                                } as RenderLegendProps)
                            }
                        />
                    </PieChart>
                )}
            </RenderStatusContent>
        </Box>
    );
};

export default ZirconPieChart;
