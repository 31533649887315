import { Box } from '@chakra-ui/react';

import React from 'react';

import SetupForm from '../../forms/setup/SetupForm';

const PieChartsSetup: React.FC = () => {
    return (
        <Box>
            <SetupForm maxNumberOfDimensions={1} maxNumberOfMetrics={1} />
        </Box>
    );
};

export default PieChartsSetup;
