import { Box, Flex, Text } from '@chakra-ui/react';
import { ComponentUI } from '@types';
import { useAppSelector } from 'src/hooks/redux';
import {
    selectComponent,
    selectComponentData
} from 'src/redux/features/blueprint/bluePrintSlice';
import { GenericTooltip } from 'src/templates/blueprint/organisms/GenericTooltip';
import { useDefaultData } from '../defaultData';
import { ComponentType } from 'common/enums';
import { formatGenericValue } from 'src/utils';
import { useMemo } from 'react';
import { DataComponent } from 'common/types';
import RenderStatusContent from '../RenderContent';

const SingleValue = ({ properties, id }: ComponentUI) => {
    const componentData = useAppSelector((state) => selectComponentData(state, id));
    const component = useAppSelector((state) =>
        selectComponent(state, id)
    ) as DataComponent;

    let data = componentData?.data;

    const fieldConfig = data?.fieldConfigs?.[data?.valueKey];
    const compareFieldConfig = useMemo(() => {
        const valueKey = data?.valueKey;
        const compareColumns = data?.fieldConfigs?.[valueKey]?.compareColumns ?? {};
        return compareColumns?.delta;
    }, [data?.compare]);

    const labelStyle = {
        width: '100%',
        ...properties.label
    };

    const valueStyle = {
        width: '100%',
        ...properties.value
    };

    let compareColor = '#515A66';
    if (data?.compare) {
        if (data.compare.delta > 0) {
            compareColor = properties.compareStyle?.upColor ?? '#05CD99';
        }
        if (data.compare.delta < 0) {
            compareColor = properties.compareStyle?.downColor ?? '#CD0508';
        }
    }

    const compareStyle = {
        width: '100%',
        fontWeight: '600',
        ...properties.compareStyle,
        color: compareColor
    };

    return (
        <RenderStatusContent
            style={properties.cardStyle}
            isLoading={componentData === undefined}
            isEmpty={!component.metrics?.length}
            isError={componentData?.error}
            isNoData={componentData === undefined}
            emptyText="Select metric to display single value."
        >
            <Box
                height="100%"
                style={properties.cardStyle}
                data-testid={`single-value-${id}`}
            >
                {data && (
                    <>
                        <GenericTooltip label={data.tooltip ?? properties.tooltip}>
                            <Text style={labelStyle}>{data.label}</Text>
                        </GenericTooltip>

                        <Flex style={valueStyle} flexDir="column">
                            {formatGenericValue(data.value, data.type, fieldConfig)}
                            {data?.compare && (
                                <Text
                                    ml="4px"
                                    as="span"
                                    style={compareStyle}
                                    lineHeight="1em"
                                >
                                    {data.compare.delta > 0 ? '+' : ''}
                                    {formatGenericValue(
                                        data.compare.delta,
                                        compareFieldConfig?.type,
                                        compareFieldConfig
                                    )}
                                </Text>
                            )}
                        </Flex>
                    </>
                )}
            </Box>
        </RenderStatusContent>
    );
};

export default SingleValue;
