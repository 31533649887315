import { Flex, Portal, Select, Text } from '@chakra-ui/react';
import { PaginationState } from '@tanstack/react-table';
import { BlueprintTableProperties } from '@types';
import { SingleValue } from 'react-select';
import CustomSelect from '../CustomSelect';
import PaginationButtons from './PaginationButtons';
import { z } from 'zod';
import { styleValidator } from 'common/validators';

const PAGINATION_OPTIONS = [
    { value: 10, label: '10' },
    { value: 20, label: '20' },
    { value: 50, label: '50' }
];

interface Table {
    getState: () => {
        pagination: PaginationState;
    };
    setPageSize: (size: number) => void;
    getPageCount: () => number;
    getRowModel: () => {
        rows: any[];
    };
    nextPage: () => void;
    previousPage: () => void;
    setPageIndex: (index: number) => void;
    getRowCount: () => number;
}

type TabbleProperties = z.infer<typeof styleValidator.table>['properties'];

interface PaginationProps {
    properties: TabbleProperties;
    table: Table;
}

export const Pagination = ({ properties, table }: PaginationProps) => {
    const { paginationRowsPerPageStyle, paginationButtonsStyle, cell } = properties;

    const {
        pagination: { pageSize, pageIndex }
    } = table.getState();

    return (
        <Flex justifyContent="flex-end" alignItems="center" gap="1rem">
            <Flex alignItems="center" justifyContent="flex-end">
                <Text
                    as="span"
                    fontWeight={600}
                    fontSize={12}
                    style={paginationRowsPerPageStyle?.labelStyle}
                >
                    {paginationRowsPerPageStyle?.label || 'Rows per page: '}
                </Text>

                <Select
                    size="sm"
                    borderRadius="4px"
                    fontSize="13px"
                    width={'4rem'}
                    background="transparent"
                    mr="-0.75rem"
                    color={paginationRowsPerPageStyle?.labelStyle?.color}
                    border="none"
                    cursor="pointer"
                    _focus={{ boxShadow: 'none' }}
                    value={pageSize}
                    onChange={(e) => table.setPageSize(Number(e.target.value))}
                >
                    {PAGINATION_OPTIONS.map((option) => (
                        <option key={option.value} value={option.value}>
                            {option.label}
                        </option>
                    ))}
                </Select>
            </Flex>
            <PaginationButtons
                pageCount={table.getPageCount()}
                pageIndex={pageIndex}
                pageSize={pageSize}
                totalRowsCount={table.getRowCount()}
                onNextPage={table.nextPage}
                onPreviousPage={table.previousPage}
                onSetPageIndex={table.setPageIndex}
                styles={paginationButtonsStyle}
            />
        </Flex>
    );
};
