import { useDeleteReportPageMutation, useDuplicateReportPageMutation } from '@api';
import {
    Box,
    Button,
    Flex,
    IconButton,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Text,
    useDisclosure
} from '@chakra-ui/react';
import { useAppSelector } from '@hooks';
import { ClientIdParams, ReportSlugParams, ViewSlugParams } from '@router';
import { HappyModal } from '@tasklogy/zircon-ui-components';
import { useState } from 'react';
import { FiCopy, FiTrash } from 'react-icons/fi';
import { MdModeEdit } from 'react-icons/md';
import { SlOptionsVertical } from 'react-icons/sl';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { selectActiveReportPageId } from 'src/redux/features/blueprint/bluePrintSlice';
import { useEditorNavigation } from 'src/router/hooks';
import { PageForm } from './PageForm';
import SwitchPublished from './SwitchPublished';
import { TPage } from './types';

export const Page: React.FC<TPage> = ({ displayName, slug, id, isActive }) => {
    const params = useParams<ReportSlugParams & ViewSlugParams & ClientIdParams>();
    const activeReportPageId = useAppSelector(selectActiveReportPageId);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [inlineEdit, setInlineEdit] = useState(false);
    const [duplicatePage] = useDuplicateReportPageMutation();
    const [deletePage] = useDeleteReportPageMutation();
    const goToEditor = useEditorNavigation();

    const onDuplicate = async () => {
        try {
            await duplicatePage({ id: id }).unwrap();
            toast.success('Page duplicated');
        } catch (error) {
            toast.error('Failed to duplicate page');
        }
    };

    const onDelete = async () => {
        try {
            await deletePage({ id: id }).unwrap();
            toast.success('Page deleted');
        } catch (error) {
            toast.error('Failed to delete page');
        }
    };

    const handleNavigate = () => {
        goToEditor(params.clientId!, params.reportSlug as string, slug);
    };

    return (
        <Flex flexDir="row" w="100%" height="45px" bg="white">
            <Box
                w="10px"
                backgroundColor={
                    activeReportPageId === id
                        ? 'rgba(255, 149, 236, 1)'
                        : 'rgba(243, 243, 243, 1)'
                }
            />
            <Flex
                flexDir="row"
                w="100%"
                border="2px solid rgba(243, 243, 243, 1)"
                borderLeft="none"
                justifyContent="space-between"
                alignItems="center"
                pl={inlineEdit ? '0.5rem' : '1rem'}
            >
                {inlineEdit ? (
                    <PageForm
                        page={{ displayName: displayName, slug: slug, id: id }}
                        onSaveCb={() => setInlineEdit(false)}
                        formStyles={{ marginRight: '1rem' }}
                    />
                ) : (
                    <Flex
                        alignItems="center"
                        width="100%"
                        gap="0.5rem"
                        justifyContent="space-between"
                    >
                        <Text
                            _hover={{ cursor: 'pointer' }}
                            onClick={() => activeReportPageId !== id && handleNavigate()}
                            fontWeight={600}
                            lineHeight={1}
                            w="100%"
                        >
                            {displayName}
                        </Text>
                    </Flex>
                )}
                <Flex alignItems="center" gap="0.5rem">
                    <SwitchPublished id={id} isActive={isActive} />
                    <Menu placement="right-start" strategy="fixed">
                        <MenuButton
                            as={IconButton}
                            aria-label="Options"
                            icon={<SlOptionsVertical />}
                            variant="icon"
                            _hover={{
                                borderColor: 'transparent'
                            }}
                            _focus={{
                                outline: 'none'
                            }}
                        />
                        <MenuList>
                            <MenuItem
                                _hover={{
                                    borderColor: 'transparent',
                                    backgroundColor: 'gray.200'
                                }}
                                _focus={{
                                    outline: 'none'
                                }}
                                icon={<MdModeEdit />}
                                onClick={() => setInlineEdit(true)}
                            >
                                Rename
                            </MenuItem>
                            <MenuItem
                                _hover={{
                                    borderColor: 'transparent',
                                    backgroundColor: 'gray.200'
                                }}
                                _focus={{
                                    outline: 'none'
                                }}
                                icon={<FiCopy />}
                                onClick={() => onDuplicate()}
                            >
                                Duplicate
                            </MenuItem>
                            <MenuItem
                                icon={<FiTrash />}
                                onClick={() => {
                                    onOpen();
                                }}
                                _hover={{
                                    backgroundColor: 'gray.200',
                                    borderColor: 'transparent'
                                }}
                                _focus={{
                                    outline: 'none'
                                }}
                            >
                                Delete
                            </MenuItem>
                        </MenuList>
                    </Menu>
                </Flex>
            </Flex>
            <HappyModal isOpen={isOpen} onClose={onClose}>
                <HappyModal.Title>Delete Page</HappyModal.Title>
                <HappyModal.Body>
                    Are you sure you want to delete this page?
                </HappyModal.Body>
                <HappyModal.Footer>
                    <Button variant="outline" onClick={onClose}>
                        Cancel
                    </Button>
                    <Button variant="solid" colorScheme="error" onClick={onDelete}>
                        Delete
                    </Button>
                </HappyModal.Footer>
            </HappyModal>
        </Flex>
    );
};
