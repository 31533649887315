import { ComponentUI, LinearGradient, LinearGradientStop } from '@types';
import { DataComponent } from 'common/types';
import {
    Bar,
    BarChart,
    CartesianGrid,
    Legend,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis
} from 'recharts';
import { useAppSelector } from 'src/hooks/redux';
import {
    selectComponent,
    selectComponentData
} from 'src/redux/features/blueprint/bluePrintSlice';
import { formatGenericValue } from 'src/utils';
import { v4 as uuidv4 } from 'uuid';
import RenderStatusContent from '../../RenderContent';
import { linearGradientAngle2Coords, useDefalut } from '../../utils';
import GoCard from './../GoCard';
import { renderLegend } from './renderLegend';
import { renderTooltipContent } from './RenderTooltipContent';
import { useGradientPalletColor, useLegendProperties } from './utils';

const StackedBarChart = ({ properties, id, height }: ComponentUI) => {
    const componentData = useAppSelector((state) => selectComponentData(state, id));
    const component = useAppSelector((state) =>
        selectComponent(state, id)
    ) as DataComponent;

    let data = componentData ?? {};

    const toPercent = (decimal: number, fixed = 0) => `${(decimal / 10).toFixed(fixed)}%`;

    const xAxisKey = data?.xAxisKey;
    const yAxisKey = data?.yAxisKeys?.[0];

    const xAxisFieldConfig = data?.fieldConfigs?.[xAxisKey];
    const yAxisConfig = data?.fieldConfigs?.[yAxisKey];

    const xFormatType = xAxisFieldConfig?.type;
    const yFormatType = yAxisConfig?.type;

    const maxValue = Math.max.apply(
        null,
        data.data?.map((entry: any) => {
            const keys = Object.keys(entry).slice(1);
            return Math.max.apply(
                null,
                keys.map((key: string) => {
                    return entry[key];
                })
            );
        })
    );
    const max = +maxValue < 1 ? +maxValue * 1.1 : +maxValue * 1.1;
    const customizedDomain = [0, +max.toFixed(4)];

    const formatXTicks = (value: any) => {
        return formatGenericValue(value, xFormatType, {
            ...xAxisFieldConfig,
            dateFormat: 'dd.MM'
        });
    };

    const formatYTicks = (value: any) => {
        return formatGenericValue(value, yFormatType, {
            ...yAxisConfig,
            compactDisplay: 'short'
        });
    };

    const { align, verticalAlign, wrapperStyle } = useLegendProperties(
        properties?.component?.legend
    );

    return (
            <RenderStatusContent
                style={{
                    ...properties.cardStyle
                }}
                isLoading={componentData === undefined}
                isEmpty={!component.dimensions?.length || !component.metrics?.length}
                isError={componentData?.error}
                isNoData={componentData === undefined  || !data?.data?.length}
                emptyText="Select at least one dimension and one metric to display the bar chart."
            >
                <GoCard width={'100%'} height={height} cardStyle={properties?.cardStyle}>
                    <ResponsiveContainer>
                        <BarChart
                            data={data?.data}
                            layout={useDefalut(
                                properties?.component?.layout,
                                'horizontal'
                            )}
                            margin={{ left: 20, right: 20 }}
                        >
                            <Legend
                                align={align}
                                verticalAlign={verticalAlign}
                                wrapperStyle={wrapperStyle}
                                content={() =>
                                    renderLegend({
                                        data,
                                        properties,
                                        legendProps: properties?.component?.legend
                                    })
                                }
                            />
                            <defs>
                                {properties?.component?.linearGradients?.map(
                                    (gradient: LinearGradient, index: number) => {
                                        const angle = gradient?.angle ?? 270;
                                        const { startPoint, endPoint } =
                                            linearGradientAngle2Coords(angle);
                                        return (
                                            <linearGradient
                                                key={uuidv4()}
                                                id={`${index.toString()}-${id}`}
                                                x1={angle ? startPoint.x : gradient.x1}
                                                y1={angle ? startPoint.y : gradient.y1}
                                                x2={angle ? endPoint.x : gradient.x2}
                                                y2={angle ? endPoint.y : gradient.y2}
                                            >
                                                {gradient.stops.map(
                                                    (stop: LinearGradientStop) => (
                                                        <stop
                                                            key={uuidv4()}
                                                            offset={stop.offset}
                                                            stopColor={stop.stopColor}
                                                            stopOpacity={stop.stopOpacity}
                                                        />
                                                    )
                                                )}
                                            </linearGradient>
                                        );
                                    }
                                )}
                            </defs>
                            <CartesianGrid
                                {...(properties?.component?.cartesianGrid ?? {})}
                            />
                            <YAxis
                                domain={customizedDomain}
                                type="number"
                                tickFormatter={formatYTicks}
                                hide={useDefalut(
                                    properties?.component?.YAxis?.hide,
                                    false
                                )}
                            />
                            <XAxis
                                dataKey={data?.xAxisKey}
                                type="category"
                                tickFormatter={formatXTicks}
                                hide={useDefalut(
                                    properties?.component?.XAxis?.hide,
                                    false
                                )}
                            />
                            <Tooltip
                                formatter={(value) => {
                                    return formatGenericValue(
                                        +value,
                                        properties?.component?.formatType
                                    );
                                }}
                                content={(payload: any) =>
                                    renderTooltipContent({
                                        ...payload,
                                        fieldConfigs: data?.fieldConfigs
                                            ? data?.fieldConfigs
                                            : {},
                                        properties,
                                        xAxisKey: data?.xAxisKey,
                                        yAxisKeys: data?.yAxisKeys
                                    })
                                }
                            />
                            {data?.yAxisKeys?.map((key: string, index: number) => {
                                const { fill } = useGradientPalletColor(
                                    properties,
                                    index,
                                    id
                                );
                                return (
                                    <Bar
                                        key={index}
                                        type="monotone"
                                        dataKey={key}
                                        fillOpacity={1}
                                        fill={fill}
                                        radius={properties?.component?.radius}
                                    />
                                );
                            })}
                        </BarChart>
                    </ResponsiveContainer>
                </GoCard>
            </RenderStatusContent>
    );
};

export default StackedBarChart;
