import { api, useDeleteConnectedSourceMutation, useListUserSourceAuthsQuery } from '@api';
import {
    Badge,
    Button,
    Card,
    CardBody,
    Flex,
    Icon,
    Text,
    Tooltip,
    useDisclosure
} from '@chakra-ui/react';
import { ColumnDef, SortingFn, createColumnHelper } from '@tanstack/react-table';
import {
    ActionDelete,
    BaseTable,
    HappyModal,
    SourceIcon
} from '@tasklogy/zircon-ui-components';
import { ClientConnectedSourceStatus } from 'common/enums';
import { format } from 'date-fns';
import { useState } from 'react';
import { FiExternalLink } from 'react-icons/fi';
import { toast } from 'react-toastify';
import { useAuth } from 'src/auth/useAuth';
import ModalStepList from 'src/blueprint/components/ModalStepList/ModalStepList';
import PairDataSourceWizard from 'src/blueprint/components/PairDataSources/PairDataSourceWizard';
import { SelectAccount } from 'src/blueprint/components/PairDataSources/Steps/SelectAccount';
import SourceAuthListStep from 'src/blueprint/components/SourceAuths/SourceAuthListStep';
import { useAppDispatch } from 'src/hooks/redux';
import { Client, ClientConnectedSource, SourceAuth } from 'src/redux/types/api';

const columnHelper = createColumnHelper<ClientConnectedSource>();

const sortStatusFn: SortingFn<any> = (rowA, rowB, _columnId) => {
    const statusA = rowA.original.status;
    const statusB = rowB.original.status;
    const statusOrder = ['ACTIVE', 'INACTIVE', 'ERROR'];
    return statusOrder.indexOf(statusA) - statusOrder.indexOf(statusB);
};

const statusTextAndVaraint = (status: ClientConnectedSourceStatus) => {
    switch (status) {
        case ClientConnectedSourceStatus.LOADING:
            return ['Loading', 'yellow'];
        case ClientConnectedSourceStatus.SOURCE_AUTH_DELETED:
            return ['Connection deleted', 'red'];
        case ClientConnectedSourceStatus.NO_ACCESS:
            return ['No access', 'red'];
        case ClientConnectedSourceStatus.LOADING_FAILED:
            return ['Loading failed', 'red'];
        case ClientConnectedSourceStatus.ACTIVE:
            return ['Active', 'green'];
        default:
            return [null, null];
    }
};

const StatusCell = ({
    status,
    sourceAuth,
    connectedSourceId
}: {
    status: ClientConnectedSourceStatus;
    sourceAuth: SourceAuth;
    connectedSourceId: number;
}) => {
    const { isOpen, onOpen, onClose } = useDisclosure();

    const {
        isOpen: isSourceAuthModalOpen,
        onOpen: onSourceAuthModalOpen,
        onClose: onSourceAuthModalClose
    } = useDisclosure();

    const dispatch = useAppDispatch();
    const [text, colorScheme] = statusTextAndVaraint(status);

    if (!text || !colorScheme) {
        return null;
    }

    const showButton =
        status === ClientConnectedSourceStatus.NO_ACCESS ||
        status === ClientConnectedSourceStatus.SOURCE_AUTH_DELETED;
    let textTooltip =
        'This client account has been disconnected due to the unknown reason. Please check if you still have the access to the account and select it to reconnect. Until you do not reconnect it, there will be no data in the live reports and you will not be able to create the new ones for this data source.';
    textTooltip =
        status === ClientConnectedSourceStatus.NO_ACCESS
            ? textTooltip
            : 'Your connection has been disconnected. Until you do not reconnect it, there will be no data in the live reports for all the clients under that connection and you will not be able to create new reports for all the clients under the connection.';

    const { data: sourceAuths } = useListUserSourceAuthsQuery(undefined);
    // Get all sourceAuths with the same source as the current sourceAuth
    const sourceAuthsWithSameSource = sourceAuths?.filter(
        (sa) => sa.source.identifier === sourceAuth.source.identifier
    );

    return (
        <Flex alignItems="baseline" gap="1rem">
            <Badge colorScheme={colorScheme}>{text}</Badge>
            {showButton && (
                <Tooltip label={textTooltip} aria-label={`tooltip-${status}`}>
                    <Text
                        color={'#314EDA'}
                        onClick={() => onOpen()}
                        fontWeight={600}
                        textDecor={'underline'}
                        _hover={{ cursor: 'pointer' }}
                    >
                        Replace the account
                    </Text>
                </Tooltip>
            )}
            {sourceAuthsWithSameSource && (
                <ModalStepList
                    isOpen={isOpen}
                    onClose={onClose}
                    innitState={{ replaceAccount: { connectedSourceId } }}
                >
                    <ModalStepList.Step modalProps={{ size: '5xl', isCentered: true }}>
                        <SourceAuthListStep
                            sourceAuths={sourceAuthsWithSameSource}
                            onAddNewSourceAuth={() => {
                                onClose();
                                onSourceAuthModalOpen();
                            }}
                        />
                    </ModalStepList.Step>
                    <ModalStepList.Step modalProps={{ size: '5xl', isCentered: true }}>
                        <SelectAccount replaceAccount />
                    </ModalStepList.Step>
                </ModalStepList>
            )}
            <PairDataSourceWizard
                isOpen={isSourceAuthModalOpen}
                onClose={onSourceAuthModalClose}
                onSuccess={() => {
                    dispatch(api.util.invalidateTags(['SourceAuths', 'Client']));
                }}
                withAccountSelection
                filterByDataSourceIdentifier={sourceAuth.source.identifier}
                withReplaceAccount
                modelStepListState={{ replaceAccount: { connectedSourceId } }}
            />
        </Flex>
    );
};

interface DeleteConfirmModalProps {
    onConfirm: () => Promise<void>;
    connectedSourceName: string;
}

const DeleteConfirmModal = ({
    onConfirm,
    connectedSourceName
}: DeleteConfirmModalProps) => {
    const {
        isOpen: isDeleteModalOpen,
        onOpen: onDeleteModalOpen,
        onClose: onDeleteModalClose
    } = useDisclosure();
    const [isDeleting, setIsDeleting] = useState(false);

    return (
        <>
            <ActionDelete onClick={onDeleteModalOpen} />
            <HappyModal isOpen={isDeleteModalOpen} onClose={onDeleteModalClose}>
                <HappyModal.Body>
                    Are you sure you want to delete{' '}
                    <Text fontWeight="bold" fontSize="1.125rem" as="span">
                        {connectedSourceName}?
                    </Text>{' '}
                    The data will be deleted from all the reports where it is being used.
                </HappyModal.Body>
                <HappyModal.Footer>
                    <Button variant="outline" onClick={onDeleteModalClose}>
                        No
                    </Button>
                    <Button
                        isLoading={isDeleting}
                        variant="solid"
                        onClick={async () => {
                            try {
                                setIsDeleting(true);
                                await onConfirm();
                                toast.success('Connected account has been deleted.');
                            } catch (e) {
                                toast.error(
                                    'An error occurred while deleting connected account.'
                                );
                                console.error(e);
                            } finally {
                                setIsDeleting(false);
                                onDeleteModalClose();
                            }
                        }}
                    >
                        Yes
                    </Button>
                </HappyModal.Footer>
            </HappyModal>
        </>
    );
};

function ActionCell({ connectedSource }: { connectedSource: ClientConnectedSource }) {
    const [deleteConnectedSource] = useDeleteConnectedSourceMutation();

    const { user } = useAuth();

    const isAdmin = user?.role === 'reportingo-admin' || user?.role === 'super-admin';

    return (
        <Flex ml="-1rem" alignItems="center" justifyContent="right">
            <DeleteConfirmModal
                connectedSourceName={connectedSource.advertiser?.advertiserName ?? ''}
                onConfirm={async () => {
                    await deleteConnectedSource({
                        connectedSourceId: connectedSource.id
                    }).unwrap();
                }}
            />
            {isAdmin && (
                <Button
                    as="a"
                    target="_blank"
                    variant="solid"
                    isDisabled={!connectedSource.dagRunUrl}
                    disabled={!connectedSource.dagRunUrl}
                    size="xs"
                    px="1rem"
                    ml="0.5rem"
                    href={
                        connectedSource.dagRunUrl ? connectedSource.dagRunUrl : undefined
                    }
                >
                    {'Import'} <Icon ml="0.5rem" as={FiExternalLink} />
                </Button>
            )}
        </Flex>
    );
}

const columns: ColumnDef<ClientConnectedSource, any>[] = [
    columnHelper.accessor('advertiser.advertiserName', {
        cell: (info) => (
            <Text as="span" fontWeight="bold">
                {info.getValue()}
            </Text>
        ),
        footer: (info) => info.column.id,
        header: () => 'Advertiser account'
    }),
    columnHelper.accessor('advertiser.advertiserId', {
        cell: (info) => <Text as="span">{info.getValue()}</Text>,
        footer: (info) => info.column.id,
        header: () => 'Account ID'
    }),
    columnHelper.accessor('sourceIdentifier', {
        cell: (info) => {
            return <SourceIcon selectedIcons={[info.getValue()]} withText />;
        },
        footer: (info) => info.column.id,
        header: () => 'Source type'
    }),
    columnHelper.accessor('status', {
        id: 'status',
        cell: (info) => {
            const sourceAuth = (info.row.original.advertiser as any).sourceAuth;
            const connectedSourceId = info.row.original.id;
            return (
                <StatusCell
                    status={info.getValue()}
                    sourceAuth={sourceAuth}
                    connectedSourceId={connectedSourceId}
                />
            );
        },
        header: () => 'Status',
        sortingFn: sortStatusFn
    }),
    columnHelper.accessor('usedInReportsCount', {
        id: 'usedInReportsCount',
        cell: (info) => info.getValue(),
        header: () => 'Used in reports'
    }),
    columnHelper.accessor('createdAt', {
        cell: (info) => format(new Date(info.getValue()), 'dd-MM-yyyy'),
        header: () => 'Created at'
    }),
    columnHelper.accessor('createdBy', {
        cell: ({ getValue }) => {
            const user = getValue();
            return user ? `${user.fullName}` : '-';
        },
        header: () => 'Connected by'
    }),
    columnHelper.accessor('client', {
        id: 'action',
        cell: (info) => <ActionCell connectedSource={info.row.original} />,
        header: () => ''
    })
];

interface Props {
    client: Client;
}

function ConnectedSourcesTable({ client }: Props) {
    const { isOpen, onOpen, onClose } = useDisclosure();

    const {
        isOpen: isSourceAuthModalOpen,
        onOpen: onSourceAuthModalOpen,
        onClose: onSourceAuthModalClose
    } = useDisclosure();

    const { data: sourceAuths } = useListUserSourceAuthsQuery(undefined);
    const dispatch = useAppDispatch();

    const dataSources = client?.connectedSources;

    return (
        <Flex flexDir="column">
            <Card>
                <CardBody>
                    <Flex w="100%" justifyContent="space-between" mb="1.5rem">
                        <Text variant="subtitle">Connected advertiser accounts</Text>
                        <Button variant="solid" onClick={onOpen}>
                            Connect advertiser account
                        </Button>
                    </Flex>
                    <BaseTable
                        variant="reports"
                        columns={columns}
                        data={dataSources ?? []}
                    />
                </CardBody>
            </Card>
            {sourceAuths && (
                <ModalStepList isOpen={isOpen} onClose={onClose}>
                    <ModalStepList.Step modalProps={{ size: '5xl', isCentered: true }}>
                        <SourceAuthListStep
                            sourceAuths={sourceAuths}
                            onAddNewSourceAuth={() => {
                                onClose();
                                onSourceAuthModalOpen();
                            }}
                        />
                    </ModalStepList.Step>
                    <ModalStepList.Step modalProps={{ size: '5xl', isCentered: true }}>
                        <SelectAccount />
                    </ModalStepList.Step>
                </ModalStepList>
            )}
            {
                <PairDataSourceWizard
                    isOpen={isSourceAuthModalOpen}
                    onClose={onSourceAuthModalClose}
                    onSuccess={() => {
                        dispatch(api.util.invalidateTags(['SourceAuths', 'Client']));
                    }}
                    withAccountSelection
                />
            }
        </Flex>
    );
}

export default ConnectedSourcesTable;
