import { useCreateReportPageMutation, useUpdateReportMutation } from '@api';
import {
    Box,
    Flex,
    Icon,
    IconButton,
    Popover,
    PopoverBody,
    PopoverContent,
    PopoverTrigger,
    Text,
    Tooltip,
    useOutsideClick
} from '@chakra-ui/react';
import { useAppSelector } from '@hooks';
import { ReportPage } from 'common/types';
import { Reorder, useAnimationControls, useMotionValue } from 'framer-motion';
import { debounce } from 'lodash';
import {
    CSSProperties,
    RefObject,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState
} from 'react';
import { IoAddCircle } from 'react-icons/io5';
import { RiFile4Line } from 'react-icons/ri';
import { toast } from 'react-toastify';
import { useHasScopePermissions } from 'src/auth/useHasScopePermissions';
import { useRaisedShadow } from 'src/editor/hooks/useRaisedShadow';
import { selectActiveReport } from 'src/redux/features/blueprint/bluePrintSlice';
import { assertIsDefined } from 'src/templates/blueprint/utils';
import { getEnv } from 'src/utils';
import { Page } from './Page';
import { TPage } from './types';
import { forwardRef } from 'react';

const liStyle: CSSProperties = {
    listStyle: 'none',
    padding: 0,
    margin: 0
};

const ulStyle: CSSProperties = {
    ...liStyle,
    position: 'relative',
    width: '360px',
    cursor: 'grab'
};

const ReorderItem = forwardRef<HTMLDivElement, { item: TPage }>(
    ({ item }, constraintRef) => {
        const y = useMotionValue(0);
        const boxShadow = useRaisedShadow(y);

        return (
            <Reorder.Item
                value={item}
                style={{ ...liStyle, boxShadow, y }}
                dragConstraints={constraintRef as RefObject<Element>}
            >
                <Page key={item.id} {...item} />
            </Reorder.Item>
        );
    }
);

ReorderItem.displayName = 'ReorderItem';

interface ReorderGroupProps {
    pages: ReportPage[];
    onReorderEnd: (items: ReportPage[]) => void;
}

const env = getEnv();

const ReorderGroupPages = ({ pages, onReorderEnd }: ReorderGroupProps) => {
    const controls = useAnimationControls();
    const [items, setItems] = useState<ReportPage[]>(pages);

    const constraintRef = useRef(null);
    useEffect(() => {
        setItems(pages);
    }, [pages]);

    const debouncedChangeHandler = useMemo(
        () =>
            debounce((value) => {
                onReorderEnd(value);
            }, 500),
        []
    );

    useEffect(() => {
        // fire if order has changed
        if (
            pages?.map((page) => page.id).join(',') !==
            items?.map((page) => page.id).join(',')
        ) {
            debouncedChangeHandler(items);
        }
        return () => debouncedChangeHandler.cancel();
    }, [items, debouncedChangeHandler]);

    return (
        <div ref={constraintRef}>
            <Reorder.Group
                axis="y"
                values={items}
                onReorder={setItems}
                style={ulStyle}
                animate={controls}
            >
                {items.map((item) => (
                    <ReorderItem key={item.id} item={item} ref={constraintRef} />
                ))}
            </Reorder.Group>
        </div>
    );
};

ReorderGroupPages.displayName = 'ReorderGroupPages';

interface PagesPopoverProps {
    isOpen: boolean;
    setIsOpen: (value: boolean) => void;
}

const PagesPopover: React.FC<PagesPopoverProps> = ({ isOpen, setIsOpen }) => {
    const constraintsRef = useRef(null);
    const [createPage] = useCreateReportPageMutation();
    const activeReport = useAppSelector(selectActiveReport);
    const [updateReport] = useUpdateReportMutation();
    const triggerButtonRef = useRef<HTMLButtonElement>(null);

    const handleClickOutside = (e: Event) => {
        if (triggerButtonRef.current?.contains(e.target as Node)) {
            return;
        }

        setIsOpen(false);
    };

    // Close the popover when clicking outside of it
    useOutsideClick({
        ref: constraintsRef,
        handler: handleClickOutside
    });

    assertIsDefined(activeReport);

    const handleCreateReportPage = useCallback(() => {
        createPage({
            displayName: 'Untitled',
            slug: `untitled-${activeReport.pages.length + 1}`,
            reportId: activeReport?.id
        });
    }, [createPage, activeReport?.id]);

    const handleReorderEnd = useCallback(
        async (items: ReportPage[]) => {
            try {
                await updateReport({
                    id: activeReport.id,
                    pageOrder: items.map((page) => page.id)
                }).unwrap();
            } catch (error) {
                console.error(error);
                toast.error('Failed to reorder pages');
            }
        },
        [updateReport, activeReport.id]
    );

    const pages = useMemo(() => {
        if (Array.isArray(activeReport?.pageOrder)) {
            const pagesFormPageOrder =
                activeReport.pageOrder
                    .map((pageId) =>
                        activeReport.pages?.find((page) => page.id === pageId)
                    )
                    .filter((page): page is ReportPage => page !== undefined) ?? [];
            const leftOverPages =
                activeReport.pages?.filter(
                    (page) => activeReport.pageOrder?.indexOf(page.id) === -1
                ) ?? [];
            return [...pagesFormPageOrder, ...leftOverPages];
        } else {
            return activeReport?.pages ?? [];
        }
    }, [activeReport?.pageOrder, activeReport?.pages]);

    const hasEditorPermission =
        useHasScopePermissions({
            any: ['editor']
        }) || env === 'test';

    return (
        <Popover
            placement="right-start"
            isOpen={isOpen}
            autoFocus={false}
            isLazy
            lazyBehavior="unmount"
        >
            <PopoverTrigger>
                <Box>
                    <Tooltip label="Pages" aria-label="Pages" placement="right">
                        <IconButton
                            variant="icon"
                            aria-label="Add new component"
                            onClick={() => {
                                setIsOpen(!isOpen);
                            }}
                            icon={<Icon h="60px" as={RiFile4Line} />}
                            ref={triggerButtonRef}
                        />
                    </Tooltip>
                </Box>
            </PopoverTrigger>

            <PopoverContent className="editor-sidebar-content" width="100%">
                <PopoverBody
                    display="flex"
                    flexDir="column"
                    gap="0.5rem"
                    ref={constraintsRef}
                >
                    <ReorderGroupPages pages={pages} onReorderEnd={handleReorderEnd} />

                    {hasEditorPermission && (
                        <Flex
                            w="fit-content"
                            gap="10px"
                            alignItems="center"
                            onClick={() => handleCreateReportPage()}
                            _hover={{
                                cursor: 'pointer',
                                borderBottom: '1px solid rgba(28, 115, 232, 1)'
                            }}
                        >
                            <Icon color="rgba(28, 115, 232, 1)" as={IoAddCircle} />
                            <Text color="rgba(28, 115, 232, 1)">Add new page</Text>
                        </Flex>
                    )}
                </PopoverBody>
            </PopoverContent>
        </Popover>
    );
};

export default PagesPopover;
