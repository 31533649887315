import { Box, Flex, Text } from '@chakra-ui/react';
import { CSSProperties } from 'react';
import { useCanvas } from 'src/blueprint/pages/editor/EditorContext';

interface RenderStatusContentProps {
    children?: React.ReactNode;
    style: CSSProperties;
    isLoading: boolean;
    isEmpty: boolean;
    isError: boolean;
    errorText?: string;
    emptyText: string;
    isNoData: boolean;
}

const RenderStatusContent: React.FC<RenderStatusContentProps> = ({
    isLoading,
    isEmpty,
    isError,
    isNoData,
    errorText,
    emptyText,
    style,
    children
}) => {
    const { state } = useCanvas();
    const renderStatus = () => {
        if (isLoading) {
            return <></>;
        }
        if (isEmpty) {
            // If the report is in editor mode, we don't want to show the empty text
            if (state.useEditorReport) {
                return emptyText;
            } else {
                return (
                    errorText ||
                    'There was an error fetching the data. Please try again later.'
                );
            }
        }
        if (isError) {
            return (
                errorText ||
                'There was an error fetching the data. Please try again later.'
            );
        }
        if (isNoData) {
            return 'For the selected period there is no data';
        }
        return null;
    };
    const status = renderStatus();

    if (!status) {
        return <>{children}</>;
    }

    return (
        <Box height="100%" style={style}>
            <Flex
                justifyContent="center"
                alignItems="center"
                height="100%"
                textAlign="center"
            >
                <Text color="inherit">{renderStatus()}</Text>
            </Flex>
        </Box>
    );
};

export default RenderStatusContent;
