import {
    Button,
    Flex,
    FormControl,
    FormLabel,
    Icon,
    IconButton,
    Input,
    Popover,
    PopoverAnchor,
    PopoverArrow,
    PopoverBody,
    PopoverContent,
    PopoverHeader,
    PopoverTrigger,
    Portal,
    Select,
    Text,
    useDisclosure,
    useOutsideClick
} from '@chakra-ui/react';
import { ColumnFormatType, DayOfWeek } from 'common/enums';
import { Reorder } from 'framer-motion';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { IconType } from 'react-icons';
import { Bs123, BsFillPlusCircleFill } from 'react-icons/bs';
import {
    MdCalendarMonth,
    MdCancel,
    MdCurrencyExchange,
    MdLink,
    MdOndemandVideo,
    MdPercent,
    MdTextFields
} from 'react-icons/md';
import { RiExchangeFill } from 'react-icons/ri';
import { toast } from 'react-toastify';
import { usePopoverContext } from 'src/editor/contexts/PopoverProvider';

interface Item {
    id: string;
    value: string | number;
    label: string;
    formatType: ColumnFormatType;
    customDisplayName?: string | null;
    customDecimalPlaces?: number | null;
    weekStart?: DayOfWeek | null;
}

interface ElementWithId extends Item {
    onClick?: (item: Item) => void;
    onRemove?: (item: Item) => void;
}

interface DnDElementProps {
    item: ElementWithId;
}

interface ValuesProps {}

interface OptionsProps {
    triggerButtonText?: string;
    id: string;
}

interface DnDElementsProps {
    children: React.ReactNode;
    values: Item[];
    options: Item[];
    elementStyle: {
        style: {
            backgroundColor: string;
            [key: string]: string;
        };
        hoverStyle: {
            backgroundColor: string;
            [key: string]: string;
        };
    };
    onValuesChange?: (values: Item[]) => void;
    maxSelectedValues?: number;
    changeLastValueTo?: (value: Item) => void;
}

type DnDElementsContext = {
    values: Item[];
    options: Item[];
    addToValues: (value: Item) => void;
    addToOptions: (option: Item) => void;
    setValues: (values: Item[]) => void;
    elementStyle: {
        style: {
            backgroundColor: string;
            [key: string]: string;
        };
        hoverStyle: {
            backgroundColor: string;
            [key: string]: string;
        };
    };
    updateValue: (value: Item) => void;
    handleSetValues: (values: Item[]) => void;
    maxSelectedValues?: number;
    changeLastValueTo: (value: Item) => void;
};

const DnDElementsContext = React.createContext<DnDElementsContext>({
    values: [],
    options: [],
    addToValues: () => {},
    addToOptions: () => {},
    setValues: () => {},
    elementStyle: {
        style: {
            backgroundColor: '#C8E6C9'
        },
        hoverStyle: {
            backgroundColor: '#A5D6A7'
        }
    },
    updateValue: () => {},
    handleSetValues: () => {},
    maxSelectedValues: undefined,
    changeLastValueTo: () => {}
});

const useDnDElements = () => {
    return React.useContext(DnDElementsContext);
};

const numericFormatTypes = [
    ColumnFormatType.NUMBER,
    ColumnFormatType.CURRENCY,
    ColumnFormatType.PERCENTAGE
];

const isNumeric = (formatType: ColumnFormatType) =>
    numericFormatTypes.includes(formatType);

const CustomDisplayNamePopover: React.FC<{
    item: Item;
}> = ({ item }) => {
    const { isOpen, onToggle, onClose } = useDisclosure();
    const { elementStyle, updateValue } = useDnDElements();
    const [value, setValue] = React.useState(item.customDisplayName ?? '');
    const [customDecimalPlaces, setCustomDecimalPlaces] = useState(
        item.customDecimalPlaces
    );

    const [weekStart, setWeekStart] = useState(item.weekStart ?? DayOfWeek.MONDAY);

    const handleSave = () => {
        updateValue({
            ...item,
            customDisplayName: value,
            customDecimalPlaces: customDecimalPlaces,
            weekStart: weekStart
        });
        toast.success(`Saved`);
        onClose();
    };
    return (
        <Popover
            returnFocusOnClose={false}
            closeOnBlur={true}
            placement="top-start"
            onClose={onClose}
            isOpen={isOpen}
        >
            <PopoverTrigger>
                <IconButton
                    onClick={onToggle}
                    variant={'unstyledIcons'}
                    minW="unset"
                    aria-label=""
                    icon={<Icon as={ColumnFormatTypeIcon[item.formatType]} />}
                />
            </PopoverTrigger>
            <Portal>
                <PopoverContent>
                    <PopoverHeader fontWeight="semibold" {...elementStyle.style}>
                        <Flex alignItems="center" gap={2}>
                            <Icon as={ColumnFormatTypeIcon[item.formatType]} />
                            <Text>{item?.label}</Text>
                        </Flex>
                    </PopoverHeader>
                    <PopoverArrow />
                    <PopoverBody>
                        <FormControl>
                            <FormLabel>Name</FormLabel>
                            <Input
                                placeholder="Name"
                                value={value}
                                onChange={(e) => setValue(e.target.value)}
                            />

                            {isNumeric(item.formatType) && (
                                <>
                                    <FormLabel mt="1rem">Decimal places</FormLabel>
                                    <Input
                                        placeholder="Decimal places"
                                        value={customDecimalPlaces ?? ''}
                                        type="number"
                                        onChange={(e) => {
                                            setCustomDecimalPlaces(
                                                Number.parseInt(e.target.value)
                                            );
                                        }}
                                    />
                                </>
                            )}

                            {item.formatType === ColumnFormatType.WEEK && (
                                <>
                                    <FormLabel mt="1rem">Start of the week</FormLabel>
                                    <Select
                                        value={weekStart}
                                        onChange={(e) => {
                                            setWeekStart(e.target.value as DayOfWeek);
                                        }}
                                    >
                                        {Object.values(DayOfWeek).map((day) => (
                                            <option key={day} value={day}>
                                                {day}
                                            </option>
                                        ))}
                                    </Select>
                                </>
                            )}

                            <Flex justifyContent="flex-end" my="1rem">
                                <Button variant="solid" onClick={() => handleSave()}>
                                    Save
                                </Button>
                            </Flex>
                        </FormControl>
                    </PopoverBody>
                </PopoverContent>
            </Portal>
        </Popover>
    );
};

const ColumnFormatTypeIcon: Record<ColumnFormatType, IconType> = {
    [ColumnFormatType.DATE]: MdCalendarMonth,
    [ColumnFormatType.NUMBER]: Bs123,
    [ColumnFormatType.CURRENCY]: MdCurrencyExchange,
    [ColumnFormatType.PERCENTAGE]: MdPercent,
    [ColumnFormatType.STRING]: MdTextFields,
    [ColumnFormatType.AD_PREVIEW]: MdOndemandVideo,
    [ColumnFormatType.LINK]: MdLink,
    [ColumnFormatType.WEEK]: MdCalendarMonth,
    [ColumnFormatType.MONTH]: MdCalendarMonth,
    [ColumnFormatType.COMPARE_NUMBER]: Bs123
};

const Element: React.FC<DnDElementProps> = ({ item }) => {
    const { elementStyle } = useDnDElements();

    return (
        <Flex height="2rem" alignItems={'center'} gap={1} mt={1}>
            <Flex
                p={1}
                alignItems={'center'}
                height={'100%'}
                borderRadius={'4px 0px 0px 4px'}
                width={26}
                {...elementStyle.style}
                _hover={{
                    cursor: 'pointer'
                }}
            >
                <CustomDisplayNamePopover item={item} />
            </Flex>
            <Flex
                {...elementStyle.style}
                px={1}
                alignItems={'center'}
                justifyContent={'space-between'}
                width={'100%'}
                height={'100%'}
                borderRadius={'0px 4px 4px 0px'}
                textOverflow={'ellipsis'}
                _hover={{
                    cursor: item.onClick ? 'pointer' : 'drag',
                    ...elementStyle.hoverStyle
                }}
                onClick={() =>
                    item.onClick
                        ? item.onClick({
                              id: item.id,
                              label: item.label,
                              value: item.value,
                              formatType: item.formatType
                          })
                        : () => {}
                }
                role="group"
            >
                <Text textStyle="h1">{item?.label}</Text>
                <Icon
                    _hover={{
                        cursor: 'pointer'
                    }}
                    opacity={0}
                    _groupHover={{
                        opacity: item.onRemove ? 1 : 0,
                        transition: 'opacity 300ms ease-in'
                    }}
                    as={MdCancel}
                    onClick={() =>
                        item.onRemove?.({
                            id: item.id,
                            label: item.label,
                            value: item.value,
                            formatType: item.formatType
                        })
                    }
                />
            </Flex>
        </Flex>
    );
};

const Values: React.FC<ValuesProps> = () => {
    const { values, setValues, addToOptions } = useDnDElements();

    const [tempValues, setTempValues] = useState(values);
    const [isSaving, setIsSaving] = useState(false);

    useEffect(() => {
        setTempValues(values);
    }, [values]);

    const cursor = isSaving ? 'wait' : 'grab';

    return (
        <Reorder.Group axis="y" values={values} onReorder={setTempValues}>
            {tempValues.map((item) => (
                <Reorder.Item
                    key={item.id}
                    value={item}
                    style={{ listStyle: 'none', cursor }}
                    drag={isSaving ? false : 'y'}
                    onDragEnd={() => {
                        setIsSaving(true);
                        setTimeout(() => {
                            setValues(tempValues);
                            setIsSaving(false);
                        }, 600);
                    }}
                >
                    <Element
                        item={{ ...item, onRemove: (item: Item) => addToOptions(item) }}
                    />
                </Reorder.Item>
            ))}
        </Reorder.Group>
    );
};

const Options: React.FC<OptionsProps> = ({ triggerButtonText = 'Vybrať', id }) => {
    const { options, addToValues, changeLastValueTo, elementStyle, values, maxSelectedValues } =
        useDnDElements();
    const [optionsList, setOptionsList] = React.useState(options);
    const popoverRef = useRef<HTMLDivElement>(null);
    const { openPopover, setOpenPopover } = usePopoverContext();
    const isOpen = openPopover === id;

    useEffect(() => {
        setOptionsList(options);
    }, [options]);

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value === '') {
            setOptionsList(options);
        } else {
            setOptionsList(
                options.filter((option) =>
                    option.label.toLowerCase().includes(e.target.value.toLowerCase())
                )
            );
        }
    };

    const handleToggle = () => {
        setOpenPopover(isOpen ? null : id);
    };

    // Close the popover when clicking outside of it
    useOutsideClick({
        ref: popoverRef,
        handler: () => {
            if (openPopover === id) {
                setOpenPopover(null);
            }
        },
    });

    return (
        <>
            <Popover placement="left-start" closeOnBlur={true} isOpen={isOpen} onClose={() => setOpenPopover(null)}
                autoFocus={false}>
                    <PopoverAnchor>
                    <Button
                        variant={'ghost'}
                        style={{ padding: 0, width: '100%' }}
                        _focus={{ border: 'none', outline: 'none' }}
                        _hover={{ border: 'none' }}
                        border={'none'}
                        onClick={handleToggle}
                    >
                        <Flex
                            height="2rem"
                            alignItems={'center'}
                            gap={1}
                            border={'1px dashed #000'}
                            borderRadius={4}
                            width={'100%'}
                        >
                            <Flex p={1} alignItems={'center'} width={26}>
                                <Icon
                                    color={elementStyle.style.backgroundColor}
                                    as={
                                        values.length === maxSelectedValues
                                            ? RiExchangeFill
                                            : BsFillPlusCircleFill
                                    }
                                />
                            </Flex>
                            <Flex px={1} width={'100%'} textOverflow={'ellipsis'}>
                                {triggerButtonText}
                            </Flex>
                        </Flex>
                    </Button>
                </PopoverAnchor>
                <Portal>
                    <PopoverContent>
                        <PopoverArrow />
                        <PopoverBody ref={popoverRef}>
                            <Flex
                                flexDir={'column'}
                                maxHeight={'20rem'}
                                overflowY="scroll"
                                pr={2}
                            >
                                <Flex p={2}>
                                    <Input
                                        type="text"
                                        placeholder="Hľadať"
                                        onChange={(e) => handleSearch(e)}
                                        borderRadius={6}
                                    />
                                </Flex>
                                {optionsList
                                    ? optionsList?.map((option) => (
                                          <Element
                                              key={option.id}
                                              item={{
                                                  ...option,
                                                  onClick: (item: Item) =>
                                                      values.length === maxSelectedValues ? changeLastValueTo(item) : addToValues(item)
                                              }}
                                          />
                                      ))
                                    : null}
                            </Flex>
                        </PopoverBody>
                    </PopoverContent>
                </Portal>
            </Popover>
        </>
    );
};

const DnDElements: React.FC<DnDElementsProps> & {
    Values: React.FC<ValuesProps>;
    Options: React.FC<OptionsProps>;
} = ({ children, values, options, elementStyle, onValuesChange, maxSelectedValues }) => {
    const addToValues = (value: Item) => {
        onValuesChange && onValuesChange([...values, value]);
    };

    const changeLastValueTo = (value: Item) => {
        const newValues = [...values];
        newValues[values.length - 1] = value;

        onValuesChange && onValuesChange(newValues);
    }

    const updateValue = useCallback(
        (value: Item) => {
            const newValues = values.map((v) => (v.id === value.id ? value : v));

            onValuesChange && onValuesChange(newValues);
        },
        [onValuesChange, values]
    );

    const addToOptions = useCallback(
        (option: any) => {
            onValuesChange &&
                onValuesChange(values.filter((value: any) => value.id !== option.id));
        },
        [onValuesChange, values]
    );

    const handleSetValues = useCallback(
        (values: any) => {
            onValuesChange && onValuesChange(values);
        },
        [onValuesChange]
    );

    return (
        <DnDElementsContext.Provider
            value={{
                values: values,
                options: options,
                addToValues,
                addToOptions,
                elementStyle,
                setValues: handleSetValues,
                updateValue,
                handleSetValues,
                maxSelectedValues,
                changeLastValueTo
            }}
        >
            {children}
        </DnDElementsContext.Provider>
    );
};

DnDElements.Values = Values;
DnDElements.Options = Options;

export default DnDElements;
