import {
    Box,
    Flex,
    Tab,
    TabIndicator,
    TabList,
    TabPanel,
    TabPanels,
    Tabs
} from '@chakra-ui/react';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux';
import { Root } from 'src/pages/Root';
import { useLazyGetReportBySlugQuery } from 'src/redux/api';
import {
    selectActiveReportPage,
    setActiveReportPageId
} from 'src/redux/features/blueprint/bluePrintSlice';
import EditorTab from './components/EditorTab';
import ReportViewsNavigation from './components/ReportPagesNavigation';
import Pages from './components/TabPages/PagesTab';

import { useEffect } from 'react';

import { useParams } from 'react-router-dom';

import { ClientIdParams, ReportSlugParams, ViewSlugParams } from '@router';
import { selectActiveReport } from 'src/redux/features/blueprint/bluePrintSlice';
import { PathNames } from 'src/router/router';
import CanvasProvider from './EditorContext';
import ReportScreenshot from './components/TabPages/ReportScreenshot';
import { PopoverProvider } from 'src/editor/contexts/PopoverProvider';

const Editor = () => {
    const activeReport = useAppSelector(selectActiveReport);
    const activePage = useAppSelector(selectActiveReportPage);
    const params = useParams<ReportSlugParams & ViewSlugParams & ClientIdParams>();
    const [fetchReport] = useLazyGetReportBySlugQuery();
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!activeReport?.pages) {
            fetchReport({ slug: params.reportSlug, clientId: +params.clientId! });
        }
    }, [fetchReport, params.reportSlug, activeReport?.pages]);

    useEffect(() => {
        if (params.viewSlug && activeReport?.pages) {
            const page = activeReport?.pages?.find((p) => p.slug === params.viewSlug);

            if (page) {
                dispatch(setActiveReportPageId(page.id));
            }
        }
    }, [activeReport?.pages, dispatch, params.viewSlug]);

    if (!activeReport) {
        return null;
    }

    return (
        <CanvasProvider
            useEditorReport={true}
            useSharedReportAPI={false}
            reportBasePathName={PathNames.DASHBOARD_REPORTS}
            height={activePage?.layout?.pageHeight}
            width={activePage?.layout?.pageWidth}
        >
            <PopoverProvider>
                <Flex
                    backgroundColor="#F8F9FA"
                    minW="100%"
                    padding="1rem"
                    justifyContent="space-between"
                >
                    <Box>
                        <ReportViewsNavigation />
                        {activePage && <Root />}
                    </Box>

                    <Box gridArea="editor">
                        <Tabs
                            position="sticky"
                            variant="unstyled"
                            height={'100vh'}
                            width={600}
                            mx={2}
                        >
                            <TabList>
                                {activePage && <Tab>Editor</Tab>}
                                <Tab>Pages</Tab>
                                <Tab>Report Screenshot</Tab>
                            </TabList>
                            <TabIndicator
                                mt="-1.5px"
                                height="2px"
                                bg="blue.500"
                                borderRadius="1px"
                            />

                            <TabPanels>
                                {activePage && (
                                    <TabPanel>
                                        <EditorTab />
                                    </TabPanel>
                                )}
                                <TabPanel>
                                    <Pages />
                                </TabPanel>
                                <TabPanel>
                                    <ReportScreenshot />
                                </TabPanel>
                            </TabPanels>
                        </Tabs>
                    </Box>
                </Flex>
            </PopoverProvider>
        </CanvasProvider>
    );
};

export default Editor;
