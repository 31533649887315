import {
    selectActiveReportPage,
    selectConnectedSources,
    selectPageThemeComponents
} from 'src/redux/features/blueprint/bluePrintSlice';
import { useAppSelector } from './redux';

import { basicComponentTypes } from 'common/enums';
import { Component, DataComponent } from 'common/types';
import { generateRandomId } from 'common/utils';
import { reportPageValidator } from 'common/validators';
import { useCallback } from 'react';
import { assertIsDefined } from 'src/templates/blueprint/utils';
import { z } from 'zod';
import { useSetComponentConfig } from './useSetComponentConfig';

export const usePasteComponent = () => {
    const setComponentConfig = useSetComponentConfig();
    const activeReportPage = useAppSelector(selectActiveReportPage);
    const pageThemeComponents = useAppSelector(selectPageThemeComponents);
    const connectedSources = useAppSelector(selectConnectedSources);

    return useCallback(
        async (component: Component & { dataSourceIdentifier?: string | null }) => {
            assertIsDefined(activeReportPage);
            const highestZIndex = Object.keys(activeReportPage.components ?? []).length;

            let tempComponent = component;
            let dataSourceIdentifier: string | undefined | null = null;
            const isBasicComponent = basicComponentTypes.includes(component.type);

            // If the component is not a basic component type, extract the dataSourceIdentifier
            if (!isBasicComponent) {
                const { dataSourceIdentifier: _dataSourceIdentifier, ...rest } =
                    component;
                tempComponent = rest;
                dataSourceIdentifier = _dataSourceIdentifier;
            }

            if (!isBasicComponent) {
                // If the component is not a basic component type, check if the source is connected
                if ((component as DataComponent).connectedSourceId) {
                    // If the source is not in the connected sources -- meaning it was copied from another client's report
                    if (
                        !connectedSources?.find(
                            (source) =>
                                source.id ===
                                (component as DataComponent).connectedSourceId
                        )
                    ) {
                        // Find the source by the dataSourceIdentifier
                        const source = connectedSources?.find(
                            (source) =>
                                source.dataSource.identifier === dataSourceIdentifier
                        );
                        // If found, set the connectedSourceId so the component can be connected to the source
                        if (source) {
                            tempComponent = {
                                ...tempComponent,
                                connectedSourceId: source.id
                            } as DataComponent;
                        } else {
                            // If the source is not found, remove the connectedSourceId, metrics, and dimensions
                            tempComponent = {
                                ...tempComponent,
                                connectedSourceId: null,
                                metrics: [],
                                dimensions: []
                            } as DataComponent;
                        }
                    }
                }
            }

            // Calculate the zIndex for the new component
            // Use the maximum value between the component's zIndex and the highest zIndex found
            const zIndex = Math.max(tempComponent?.zIndex ?? 0, highestZIndex);
            tempComponent = { ...tempComponent, zIndex };

            // Update the component with the new id
            tempComponent = { ...tempComponent, id: generateRandomId() };

            try {
                const validated = reportPageValidator.component.parse(tempComponent);
                await setComponentConfig(validated);
                return validated;
            } catch (err) {
                if (err instanceof z.ZodError) {
                    console.log('🚀 ~ err:', err, tempComponent);
                    console.log(err.issues);
                }
                return null;
            }
        },
        [setComponentConfig, activeReportPage, pageThemeComponents]
    );
};
