import { useGetSourceAuthUriMutation } from '@api';
import dv360 from '@assets/svg/dataSources/dv360.svg';
import facebookInsights from '@assets/svg/dataSources/facebookInsights.svg';
import googleAds from '@assets/svg/dataSources/googleAds.svg';
import googleAnalytics from '@assets/svg/dataSources/googleAnalytics.svg';
import instagramInsights from '@assets/svg/dataSources/instagramInsights.svg';
import meta from '@assets/svg/dataSources/meta.svg';
import pinterest from '@assets/svg/dataSources/pinterest.svg';
import { Box, Card, Flex, Grid, GridItem, Image, Text } from '@chakra-ui/react';
import gemiusGde from '@assets/svg/dataSources/gemiusGde.png';
import adForm from '@assets/svg/dataSources/adform.png';
import tiktok from '@assets/svg/dataSources/tiktok.png';

import { ActionMenu } from '@tasklogy/zircon-ui-components';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { ClientIdParams } from 'src/router/types';
import { useModalStepList } from '../../ModalStepList/ModalStepList';
import { DataSourceIdentifier } from 'common/enums';
import { StepKeys } from '../enums';

export enum DataSourceState {
    ACTIVE = 'ACTIVE',
    NOT_CONNECTED = 'NOT_CONNECTED',
    IS_BEING_APPROVED = 'IS_BEING_APPROVED'
}

const DataSourceStateDescription = {
    ACTIVE: 'Available',
    NOT_CONNECTED: 'Unavailable',
    IS_BEING_APPROVED: 'Awaiting approval'
};

const DataSourceStateColor = {
    ACTIVE: '#1BC30C',
    NOT_CONNECTED: 'transparent',
    IS_BEING_APPROVED: '#FCBB5B'
};

export interface PairedDataSource {
    title: string;
    logo: string;
    state: DataSourceState;
    disabled?: boolean;
    sourceIdentifier?: DataSourceIdentifier;
}

interface PairDataSourcesProps {
    connectedSources?: any[];
    filterByDataSourceIdentifier?: DataSourceIdentifier;
}

const sources: PairedDataSource[] = [
    {
        title: 'GA4',
        logo: googleAnalytics,
        sourceIdentifier: DataSourceIdentifier.GA4,
        state: DataSourceState.ACTIVE
    },
    {
        title: 'Google Ads',
        logo: googleAds,
        sourceIdentifier: DataSourceIdentifier.GOOGLE_ADS,
        state: DataSourceState.ACTIVE
    },
    {
        title: 'Facebook Insights',
        logo: facebookInsights,
        state: DataSourceState.ACTIVE,
        sourceIdentifier: DataSourceIdentifier.FACEBOOK_INSIGHTS
    },
    {
        title: 'Instagram Insights',
        logo: instagramInsights,
        state: DataSourceState.ACTIVE,
        sourceIdentifier: DataSourceIdentifier.INSTAGRAM_INSIGHTS
    },

    {
        title: 'Meta Ads',
        logo: meta,
        state: DataSourceState.ACTIVE,
        sourceIdentifier: DataSourceIdentifier.META,
        disabled: false
    },
    {
        title: 'Display & Video 360',
        logo: dv360,
        state: DataSourceState.ACTIVE,
        sourceIdentifier: DataSourceIdentifier.DV360,
        disabled: false
    },
    {
        title: 'Adform',
        logo: adForm,
        state: DataSourceState.ACTIVE,
        sourceIdentifier: DataSourceIdentifier.ADFORM
    },
    {
        title: 'Gemius GDE',
        logo: gemiusGde,
        state: DataSourceState.ACTIVE,
        sourceIdentifier: DataSourceIdentifier.GEMIUS_GDE
    },
    {
        title: 'TikTok',
        logo: tiktok,
        state: DataSourceState.ACTIVE,
        sourceIdentifier: DataSourceIdentifier.TIKTOK
    },
    {
        title: 'Pinterest',
        logo: pinterest,
        state: DataSourceState.NOT_CONNECTED,
        disabled: true
    }
];

const DataSourceStateDisplay = ({ state }: { state: DataSourceState }) => {
    return (
        <Flex gap={'1rem'} alignItems={'center'}>
            {state !== DataSourceState.NOT_CONNECTED && (
                <Box
                    bg={DataSourceStateColor[state]}
                    h="10px"
                    w="10px"
                    borderRadius={50}
                />
            )}
            <Text fontWeight={300} color={'rgba(74, 85, 100, 1)'}>
                {DataSourceStateDescription[state]}
            </Text>
        </Flex>
    );
};

const ListDataSources: React.FC<PairDataSourcesProps> = ({ connectedSources, filterByDataSourceIdentifier }) => {
    const params = useParams<ClientIdParams>();
    const { setState, state, setStepByKey } = useModalStepList();
    const [getSourceAuthUri] = useGetSourceAuthUriMutation();
    let sourcesToDisplay = [];

    if (connectedSources && connectedSources.length > 0) {
        sourcesToDisplay = sources
            .map((source) => {
                const foundConnectedSource = connectedSources.find(
                    (connectedSource) =>
                        connectedSource?.sourceAuth &&
                        connectedSource?.sourceAuth?.source?.identifier ===
                            source.sourceIdentifier
                );
                if (foundConnectedSource) {
                    return {
                        ...source,
                        state: foundConnectedSource.sourceAuth?.status,
                        disabled: false
                    };
                } else {
                    return {
                        ...source,
                        state: DataSourceState.NOT_CONNECTED
                    };
                }
            })
            .filter(Boolean);
    } else {
        sourcesToDisplay = sources;
    }

    // Filter sources by identifier
    if (filterByDataSourceIdentifier) {
        sourcesToDisplay = sourcesToDisplay.filter(
            (source) => source.sourceIdentifier === filterByDataSourceIdentifier
        );
    }

    const handlePairDataSource = async (dataSourceIdentifier: DataSourceIdentifier) => {
        setState({ ...state, sourceIdentifier: dataSourceIdentifier });

        if (dataSourceIdentifier === DataSourceIdentifier.GEMIUS_GDE) {
            setStepByKey(StepKeys.LoginWindow);
            return;
        }

        if (
            [
                DataSourceIdentifier.FACEBOOK_INSIGHTS,
                DataSourceIdentifier.INSTAGRAM_INSIGHTS,
                DataSourceIdentifier.META
            ].includes(dataSourceIdentifier)
        ) {
            setStepByKey(StepKeys.LoginWithFacebook);
            return;
        }

        try {
            const response = await getSourceAuthUri({
                clientId: +params.clientId!,
                sourceIdentifier: dataSourceIdentifier
            }).unwrap();
            window.open(response.uri, '_blank');
            setState({
                ...state,
                clientId: +params.clientId!,
                attemptId: response.attemptId,
                uri: response.uri
            });
        } catch (error) {
            toast.error('Failed to pair data source');
            return;
        }
        setStepByKey(StepKeys.AuthInProgress);
    };

    return (
        <>
            <Flex alignItems={'center'}>
                <Grid
                    templateColumns="repeat(12, 1fr)"
                    gap={2}
                    rowGap={'20px'}
                    columnGap={'20px'}
                >
                    {sourcesToDisplay.map((source, index) => (
                        <GridItem
                            colSpan={3}
                            key={index}
                            _hover={{
                                cursor: source.disabled ? 'not-allowed' : 'pointer'
                            }}
                        >
                            <Card
                                role="group"
                                height={'100px'}
                                w="100%"
                                px="10px"
                                py="10px"
                                shadow="0px 3px 14px 0px rgba(0, 0, 0, 0.03)"
                                border={'1px solid rgba(223, 223, 223, 1)'}
                                display={'flex'}
                                flexDir="column"
                                justifyContent={'space-between'}
                                onClick={() => {
                                    if (!source.disabled) {
                                        handlePairDataSource(source.sourceIdentifier!);
                                    }
                                }}
                                backgroundColor={
                                    source.disabled ? 'rgba(223, 223, 223, 1)' : 'white'
                                }
                            >
                                <Flex
                                    justifyContent="space-between"
                                    alignItems="flex-start"
                                >
                                    <Text
                                        fontWeight={600}
                                        color={'rgba(82, 90, 102, 1)'}
                                        transition={'color 0.3s'}
                                        _groupHover={{
                                            color: source.disabled
                                                ? 'rgba(82, 90, 102, 1)'
                                                : 'brand.primary'
                                        }}
                                    >
                                        {source.title}
                                    </Text>
                                    <ActionMenu
                                        menuButtonVariant="unstyledIcon"
                                        items={[]}
                                        size=""
                                    />
                                </Flex>
                                <Flex
                                    justifyContent={'space-between'}
                                    alignItems={'flex-start'}
                                >
                                    <DataSourceStateDisplay state={source.state} />
                                    <Image
                                        filter={
                                            source.disabled
                                                ? 'grayscale(var(--value, 100%))'
                                                : ''
                                        }
                                        src={source.logo}
                                        w={8}
                                        h={8}
                                    />
                                </Flex>
                            </Card>
                        </GridItem>
                    ))}
                </Grid>
            </Flex>
        </>
    );
};

export default ListDataSources;
