import { useCreateConnectedSourceMutation, useReplaceConnectedSourceMutation } from '@api';
import {
    Box,
    Button,
    Flex,
    FormControl,
    FormErrorMessage,
    Grid,
    GridItem,
    ModalBody,
    ModalFooter,
    Text
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { StyledSelect } from '@tasklogy/zircon-ui-components';
import { DataSourceIdentifier } from 'common/enums';
import { useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { SourceAuth } from 'src/redux/types/api';
import { ClientIdParams } from 'src/router/types';
import { z } from 'zod';
import { createConnectedSourceValidation } from '../../../pages/ClientDetail/validation';
import { useModalStepList } from '../../ModalStepList/ModalStepList';

type FormData = z.infer<typeof createConnectedSourceValidation>;

export const SelectAccount = ({ onGoBack, replaceAccount = false }: { onGoBack?: () => void, replaceAccount?: boolean }) => {
    const params = useParams<ClientIdParams>();
    const { onClose, state, goToPreviousStep } = useModalStepList();
    const [createConnectedSource, { isLoading: isCreating }] = useCreateConnectedSourceMutation();
    const [replaceConnectedSource, { isLoading: isReplacing }] = useReplaceConnectedSourceMutation();

    const isLoading = isCreating || isReplacing;

    const sourceAuth = state.sourceAuth as SourceAuth;

    const accountDetails = sourceAuth?.accountDetails;

    const {
        control,
        watch,
        resetField,
        handleSubmit,
        formState
    } = useForm<FormData>({
        resolver: zodResolver(createConnectedSourceValidation),
        mode: 'onSubmit'
    });
    const { errors } = formState;

    const selectedParentAccount = watch('parentAccount');
    const selectedAdvertiser = watch('advertiser');

    const parentAccountOptions = useMemo(() => {
        if (
            ![DataSourceIdentifier.DV360, DataSourceIdentifier.GA4].includes(
                sourceAuth.source.identifier
            )
        ) {
            return null;
        }

        const parentAccountsMap = new Map<
            string,
            { label: string; value: FormData['parentAccount'] }
        >();

        sourceAuth.advertisers?.forEach((advertiser) => {
            if (advertiser.parentAccountId) {
                parentAccountsMap.set(advertiser.parentAccountId, {
                    label: advertiser.parentAccountName,
                    value: {
                        parentAccountId: advertiser.parentAccountId,
                        parentAccountName: advertiser.parentAccountName
                    }
                });
            }
        });

        return [...Array.from(parentAccountsMap.values())];
    }, [sourceAuth.advertisers, sourceAuth.source.identifier]);

    const advertiserOptions = useMemo(() => {
        const filtered = parentAccountOptions
            ? sourceAuth.advertisers.filter(
                  (advertiser) =>
                      advertiser.parentAccountId ===
                      selectedParentAccount?.parentAccountId
              )
            : sourceAuth.advertisers;

        return filtered.map((advertiser) => ({
            label: advertiser.advertiserName,
            value: {
                id: advertiser.id,
                advertiserId: advertiser.advertiserId,
                advertiserName: advertiser.advertiserName,
                hasAccess: advertiser.hasAccess
            }
        }));
    }, [sourceAuth.advertisers, parentAccountOptions, selectedParentAccount]);

    const handleSave = handleSubmit(async (values) => {
        if (replaceAccount) {
            if (!state?.replaceAccount?.connectedSourceId) {
                toast.error('Failed to replace data source - connectedSourceId is not set in state');
                console.error('Replace account is enabled but connectedSourceId is not set in state');
                return;
            }
            try {
                await replaceConnectedSource({
                    connectedSourceId: state.replaceAccount.connectedSourceId,
                    newAdvertiserId: values.advertiser!.id
                }).unwrap();
                toast.success('Data source was successfully replaced');
            } catch (error: any) {
                if ('status' in error && error.status === 409) {
                    toast.error('This advertiser is already connected.', {
                        autoClose: false
                    });
                    return;
                }
                toast.error('Failed to replace data source');
            }
        } else {
            try {
                await createConnectedSource({
                    clientId: Number(params.clientId),
                    advertiserId: values.advertiser!.id
                }).unwrap();
                toast.success('Data source was successfully added');
            } catch (error: any) {
                if ('status' in error && error.status === 409) {
                    toast.error('This advertiser is already connected.', {
                        autoClose: false
                    });
                    return;
                }
    
                toast.error('Failed to add data source');
            }
        }
        onClose();
    });

    return (
        <>
            <ModalBody>
                <Box bg="#FAFCFF" p="1rem">
                    <Grid templateColumns="repeat(12, 1fr)" gap={4}>
                        <GridItem colSpan={12}>
                            <Flex alignItems={'baseline'} gap={2} mb={5}>
                                <Text fontWeight={600} fontSize={22}>
                                    Select account
                                </Text>
                                <Text>({accountDetails?.userInfo?.email})</Text>
                            </Flex>
                        </GridItem>
                        {parentAccountOptions && (
                            <GridItem colSpan={6}>
                                <FormControl
                                    isInvalid={Boolean(errors.parentAccount)}
                                    isRequired
                                >
                                    <Controller
                                        name="parentAccount"
                                        control={control}
                                        render={({ field }) => (
                                            <StyledSelect
                                                placeholder="Select parent account"
                                                style={{
                                                    width: '100%'
                                                }}
                                                options={
                                                    parentAccountOptions
                                                        ? parentAccountOptions
                                                        : []
                                                }
                                                onChange={(e: any) => {
                                                    resetField('advertiser');
                                                    field.onChange(e.value);
                                                }}
                                            />
                                        )}
                                    />
                                    <FormErrorMessage>
                                        {errors?.parentAccount?.message}
                                    </FormErrorMessage>
                                </FormControl>
                            </GridItem>
                        )}
                        <GridItem colSpan={6}>
                            <FormControl
                                isInvalid={Boolean(errors.advertiser)}
                                isRequired
                            >
                                <Controller
                                    name="advertiser"
                                    control={control}
                                    render={({ field }) => (
                                        <StyledSelect
                                            placeholder="Select account"
                                            style={{
                                                width: '100%'
                                            }}
                                            onChange={(e: any) => {
                                                field.onChange(e.value);
                                            }}
                                            options={advertiserOptions}
                                            value={
                                                selectedAdvertiser
                                                    ? advertiserOptions.find(
                                                          (option) =>
                                                              option.value?.id ===
                                                              selectedAdvertiser?.id
                                                      )
                                                    : null
                                            }
                                        />
                                    )}
                                />
                                <FormErrorMessage>
                                    {errors?.advertiser?.message}
                                </FormErrorMessage>
                            </FormControl>
                        </GridItem>
                    </Grid>
                </Box>
            </ModalBody>
            <ModalFooter>
                <Flex gap={'1rem'}>
                    <Button
                        variant="outline"
                        onClick={() => {
                            if (onGoBack) {
                                onGoBack();
                            } else {
                                goToPreviousStep();
                            }
                        }}
                    >
                        Back
                    </Button>
                    <Button
                        variant="solid"
                        isLoading={isLoading}
                        onClick={() => handleSave()}
                    >
                        Save
                    </Button>
                </Flex>
            </ModalFooter>
        </>
    );
};
