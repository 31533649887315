import { basicComponentTypes } from 'common/enums';
import { Component, DataComponent } from 'common/types';
import { useCallback } from 'react';
import { toast } from 'react-toastify';
import { selectConnectedSources } from 'src/redux/features/blueprint/bluePrintSlice';
import { useAppSelector } from './redux';

interface CopyComponentsParams {
    stateType: 'copy-components';
    fromReportPageSlug?: string;
    components: Component[];
}

export const useCopyComponents = () => {
    const connectedSources = useAppSelector(selectConnectedSources);

    const getSourceIdentifierById = useCallback(
        (id?: number | null) => {
            return connectedSources?.find((source) => source.id === id)?.dataSource
                ?.identifier;
        },
        [connectedSources]
    );

    return useCallback(
        async ({ stateType, fromReportPageSlug, components }: CopyComponentsParams) => {
            const componentsToCopy = components.map((component) => {
                const isBasicComponent = basicComponentTypes.includes(component.type);
                if (isBasicComponent) {
                    return component;
                } else {
                    const sourceIdentifier = getSourceIdentifierById(
                        (component as DataComponent).connectedSourceId
                    );
                    if (sourceIdentifier) {
                        return {
                            ...component,
                            dataSourceIdentifier: sourceIdentifier
                        };
                    } else {
                        // If the source is not found, remove the connectedSourceId, metrics, and dimensions
                        return {
                            ...component,
                            dataSourceIdentifier: sourceIdentifier
                        };
                    }
                }
            });
            try {
                await navigator.clipboard.writeText(
                    JSON.stringify({
                        stateType,
                        fromReportPageSlug,
                        components: componentsToCopy
                    })
                );
                toast.success('Components copied', {
                    position: 'bottom-right'
                });
            } catch (error) {
                toast.error('Error copying components', {
                    position: 'bottom-right'
                });
            }
        },
        [connectedSources]
    );
};
