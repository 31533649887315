import { Box, Flex, IconButton } from '@chakra-ui/react';
import { useAppSelector } from '@hooks';
import { ComponentType } from 'common/enums';
import { reportPageValidator } from 'common/validators';
import _ from 'lodash';
import React, { useCallback } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import {
    PiLinkSimpleHorizontalBreakLight,
    PiLinkSimpleHorizontalLight
} from 'react-icons/pi';
import { selectActiveReportPage } from 'src/redux/features/blueprint/bluePrintSlice';
import { z } from 'zod';
import FormNumberInput from '../inputs/Number';
import FormPropertyRow from '../layout/FormPropertyRow';
import FormPropertyTitle from '../layout/FormPropertyTitle';
import FormTitle from '../layout/FormTitle';

type FormData = z.infer<typeof reportPageValidator.baseComponent>;

interface LockAspectRatioButtonProps {
    isLocked: boolean;
    setIsLocked: (isLocked: boolean) => void;
}

const LockAspectRatioButton: React.FC<LockAspectRatioButtonProps> = ({
    isLocked,
    setIsLocked
}) => {
    const icon = isLocked ? (
        <PiLinkSimpleHorizontalLight style={{ transform: 'rotate(90deg)' }} />
    ) : (
        <PiLinkSimpleHorizontalBreakLight style={{ transform: 'rotate(90deg)' }} />
    );
    return (
        <IconButton
            variant="editor"
            aria-label="link"
            icon={icon}
            onClick={() => setIsLocked(!isLocked)}
        />
    );
};

const PositionFormTwo = () => {
    const [isLocked, setIsLocked] = React.useState(false);
    const methods = useFormContext<FormData>();
    const activeReportPage = useAppSelector(selectActiveReportPage);
    const { formState, setValue } = methods;
    const { errors } = formState;

    const _w = useWatch({ name: 'w' });
    const _h = useWatch({ name: 'h' });
    const _x = useWatch({ name: 'x' });
    const _y = useWatch({ name: 'y' });
    const _type = useWatch({ name: 'type' });

    const componentWidth = Number(_w);
    const componentHeight = Number(_h);
    const componentX = _x;
    const componentY = _y;
    const componentType = _type;

    const pageLayout = activeReportPage?.layout ?? { pageWidth: 0, pageHeight: 0 };

    const maxW = pageLayout.pageWidth;
    const maxH = pageLayout.pageHeight;
    const _componentWidth = componentWidth === 0 ? 10 : Number(componentWidth) || maxW;
    const _componentHeight = componentHeight === 0 ? 10 : Number(componentHeight) || maxH;
    const maxX = pageLayout.pageWidth - _componentWidth;
    const maxY = pageLayout.pageHeight - _componentHeight;

    const getAspectRatio = (width: number, height: number) => {
        return width / height;
    };

    const getValidWidth = (value: number) => {
        return componentX + value > maxW ? maxW - componentX : value;
    };

    const getValidHeight = (value: number) => {
        return componentY + value > maxH ? maxH - componentY : value;
    };

    const getValidX = (value: number) => {
        if (value < 0) {
            return 0;
        } else if (value + componentWidth > maxW) {
            return maxW - componentWidth;
        }
        return value;
    };

    const getValidY = (value: number) => {
        if (value < 0) {
            return 0;
        } else if (value + componentHeight > maxH) {
            return maxH - componentHeight;
        }
        return value;
    };

    const getValidZIndex = (value: number) => {
        if (value < 0) {
            return 0;
        } else if (value > 500) {
            return 500;
        }
        return value;
    };

    const handleWidthHeightChange = useCallback(
        (value: string, path: any) => {
            if (isLocked) {
                const _value = Number(value) === 0 ? 10 : Number(value);
                if (path === 'w') {
                    if (Number(value) === 0) {
                        // @ts-ignore
                        setValue('w', '');
                        // @ts-ignore
                        setValue('h', '');
                    } else {
                        const aspectRatio = getAspectRatio(
                            _componentWidth,
                            _componentHeight
                        );
                        const newHeight = +(_value / aspectRatio).toFixed(2);
                        // @ts-ignore
                        setValue('w', _value, { shouldValidate: false });
                        // @ts-ignore
                        setValue('h', newHeight, { shouldValidate: false });
                    }
                }
                if (path === 'h') {
                    if (Number(value) === 0) {
                        // @ts-ignore
                        setValue('w', '');
                        // @ts-ignore
                        setValue('h', '');
                    } else {
                        const aspectRatio = getAspectRatio(
                            _componentWidth,
                            _componentHeight
                        );
                        const newWidth = +(_value * aspectRatio).toFixed(2);
                        // @ts-ignore
                        setValue('h', _value, { shouldValidate: false });
                        // @ts-ignore
                        setValue('w', newWidth, { shouldValidate: false });
                    }
                }
            } else {
                setValue(path, value, { shouldValidate: true });
            }
        },
        [isLocked, _componentWidth, _componentHeight, maxH, maxW, setValue]
    );

    const handlePress = (value: string, path: any) => {
        let _value = Number(value) === 0 ? 10 : Number(value);
        _value = (path === 'h' || path === 'w') && _value < 10 ? 10 : _value;
        if (isLocked) {
            if (path === 'w') {
                const aspectRatio = getAspectRatio(_componentWidth, _componentHeight);
                let newHeight = +(_value / aspectRatio).toFixed(2) || 10;
                newHeight = getValidHeight(newHeight);
                setValue('w', _value, { shouldDirty: true });
                setValue('h', newHeight, { shouldDirty: true });
            } else if (path === 'h') {
                const aspectRatio = getAspectRatio(_componentWidth, _componentHeight);
                let newWidth = +(_value * aspectRatio).toFixed(2) || 10;
                newWidth = getValidWidth(newWidth);
                setValue('h', _value, { shouldDirty: true });
                setValue('w', newWidth, { shouldDirty: true });
            }
        } else {
            if (path === 'w') {
                const validW = getValidWidth(_value);
                setValue('w', validW, { shouldDirty: true });
                return;
            }
            if (path === 'h') {
                const validH = getValidHeight(_value);
                setValue('h', validH, { shouldDirty: true });
                return;
            }
            if (path === 'x') {
                const validX = getValidX(Number(value));
                setValue('x', validX, { shouldDirty: true });
                return;
            }
            if (path === 'y') {
                const validY = getValidY(Number(value));
                setValue('y', validY, { shouldDirty: true });
                return;
            }
            if (path === 'zIndex') {
                const validZIndex = getValidZIndex(Number(value));
                setValue('zIndex', validZIndex, { shouldDirty: true });
                return;
            }
        }
    };

    const handleKeyDown = (
        e: React.KeyboardEvent<HTMLInputElement>,
        value: string,
        path: any
    ) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            handlePress(value, path);
            (e.target as HTMLInputElement).blur();
        }
    };

    const handleKeyUp = useCallback(
        _.debounce(
            (e: React.KeyboardEvent<HTMLInputElement>, value: string, path: any) => {
                if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                    e.preventDefault();
                    handlePress(value, path);
                }
            },
            300
        ),
        [isLocked]
    );

    const handleBlur = (
        e: React.FocusEvent<HTMLInputElement>,
        value: string,
        path: any
    ) => {
        e.preventDefault();
        handlePress(value, path);
    };

    return (
        <Box>
            <Flex flexDir="column">
                <Flex my={4}>
                    <FormTitle title="Position component" />
                </Flex>
                <FormPropertyRow>
                    <FormPropertyTitle title="Size" />
                    <Flex gap="1rem" alignItems="center">
                        <Controller
                            name="w"
                            defaultValue={0}
                            render={({ field: { value, onChange, onBlur, ...rest } }) => (
                                <FormNumberInput
                                    label="W"
                                    min={10}
                                    value={value}
                                    onChange={(e) =>
                                        handleWidthHeightChange(e.target.value, 'w')
                                    }
                                    onKeyDown={(e: any) =>
                                        handleKeyDown(e, e.target.value, 'w')
                                    }
                                    onKeyUp={(e: any) =>
                                        handleKeyUp(e, e.target.value, 'w')
                                    }
                                    onBlur={(e: any) =>
                                        handleBlur(e, e.target.value, 'w')
                                    }
                                    onWheel={(e: any) =>
                                        handleKeyUp(e, e.target.value, 'w')
                                    }
                                    max={maxW}
                                    {...rest}
                                    error={errors.w}
                                    width="80px"
                                    type="number"
                                />
                            )}
                        />
                        <Controller
                            name="h"
                            defaultValue={0}
                            render={({ field: { value, onChange, onBlur, ...rest } }) => (
                                <FormNumberInput
                                    label="H"
                                    min={10}
                                    value={value}
                                    onChange={(e) =>
                                        handleWidthHeightChange(e.target.value, 'h')
                                    }
                                    onKeyDown={(e: any) =>
                                        handleKeyDown(e, e.target.value, 'h')
                                    }
                                    onKeyUp={(e: any) =>
                                        handleKeyUp(e, e.target.value, 'h')
                                    }
                                    onBlur={(e: any) =>
                                        handleBlur(e, e.target.value, 'h')
                                    }
                                    onWheel={(e: any) =>
                                        handleKeyUp(e, e.target.value, 'h')
                                    }
                                    {...rest}
                                    max={maxH}
                                    error={errors.h}
                                    width="80px"
                                    type="number"
                                />
                            )}
                        />
                        {[ComponentType.IMAGE, ComponentType.LOGO].includes(
                            componentType
                        ) && (
                            <LockAspectRatioButton
                                isLocked={isLocked}
                                setIsLocked={setIsLocked}
                            />
                        )}
                    </Flex>
                </FormPropertyRow>
                <FormPropertyRow>
                    <FormPropertyTitle title="Position" />
                    <Flex gap="1rem">
                        <Controller
                            name="x"
                            defaultValue={0}
                            render={({ field: { value, onChange, onBlur, ...rest } }) => (
                                <FormNumberInput
                                    label="X"
                                    min={0}
                                    value={value}
                                    // @ts-ignore
                                    onChange={(e) => setValue('x', e.target.value)}
                                    onKeyDown={(e: any) =>
                                        handleKeyDown(e, e.target.value, 'x')
                                    }
                                    onKeyUp={(e: any) =>
                                        handleKeyUp(e, e.target.value, 'x')
                                    }
                                    onBlur={(e: any) =>
                                        handleBlur(e, e.target.value, 'x')
                                    }
                                    onWheel={(e: any) =>
                                        handleKeyUp(e, e.target.value, 'x')
                                    }
                                    {...rest}
                                    max={maxX}
                                    error={errors.x}
                                    width="80px"
                                    type="number"
                                />
                            )}
                        />
                        <Controller
                            name="y"
                            defaultValue={0}
                            render={({ field: { value, onChange, onBlur, ...rest } }) => (
                                <FormNumberInput
                                    label="Y"
                                    min={0}
                                    value={value}
                                    // @ts-ignore
                                    onChange={(e) => setValue('y', e.target.value)}
                                    onKeyDown={(e: any) =>
                                        handleKeyDown(e, e.target.value, 'y')
                                    }
                                    onKeyUp={(e: any) =>
                                        handleKeyUp(e, e.target.value, 'y')
                                    }
                                    onBlur={(e: any) =>
                                        handleBlur(e, e.target.value, 'y')
                                    }
                                    onWheel={(e: any) =>
                                        handleKeyUp(e, e.target.value, 'y')
                                    }
                                    {...rest}
                                    max={maxY}
                                    error={errors.y}
                                    width="80px"
                                    type="number"
                                />
                            )}
                        />
                    </Flex>
                </FormPropertyRow>
                <FormPropertyRow>
                    <FormPropertyTitle title="Z-Index" />
                    <Flex gap="1rem">
                        <Controller
                            name="zIndex"
                            defaultValue={0}
                            render={({ field: { value, onChange, onBlur, ...rest } }) => (
                                <FormNumberInput
                                    min={0}
                                    label="Z"
                                    value={value}
                                    // @ts-ignore
                                    onChange={(e) => setValue('zIndex', e.target.value)}
                                    onKeyDown={(e: any) =>
                                        handleKeyDown(e, e.target.value, 'zIndex')
                                    }
                                    onKeyUp={(e: any) =>
                                        handleKeyUp(e, e.target.value, 'zIndex')
                                    }
                                    onBlur={(e: any) =>
                                        handleBlur(e, e.target.value, 'zIndex')
                                    }
                                    onWheel={(e: any) =>
                                        handleKeyUp(e, e.target.value, 'zIndex')
                                    }
                                    {...rest}
                                    max={500}
                                    error={errors.zIndex}
                                    width="80px"
                                    type="number"
                                />
                            )}
                        />
                    </Flex>
                </FormPropertyRow>
            </Flex>
        </Box>
    );
};

export default PositionFormTwo;
