import { Icon, IconButton, Stack, Text } from '@chakra-ui/react';
import { CSSProperties } from 'react';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';

interface Props {
    pageCount: number;
    pageIndex: number;
    pageSize: number;
    totalRowsCount: number;
    onSetPageIndex: (pageIndex: number) => void;
    onPreviousPage: () => void;
    onNextPage: () => void;
    styles: Record<string, CSSProperties>;
}

export default function PaginationButtons({
    pageCount,
    pageIndex,
    totalRowsCount,
    pageSize,
    onNextPage,
    onPreviousPage,
    styles
}: Props) {
    const isFirstPage = pageIndex === 0;
    const isLastPage = pageIndex === pageCount - 1;

    const textColor = 'black';

    const currentIndexOfFirstRow = pageIndex * pageSize + 1;
    const currentIndexOfLastRow =
        (pageIndex + 1) * pageSize < totalRowsCount
            ? (pageIndex + 1) * pageSize
            : totalRowsCount;

    return (
        <Stack
            direction="row"
            justifyContent="end"
            alignItems="center"
            alignSelf="flex-end"
            spacing="4px"
            color="black"
        >
            <Text fontFamily="Roboto" fontSize="12px" style={styles.rowsCount}>
                {currentIndexOfFirstRow} - {currentIndexOfLastRow} / {totalRowsCount}
            </Text>
            <IconButton
                icon={
                    <Icon
                        as={MdChevronLeft}
                        w="28px"
                        h="28px"
                        color={textColor}
                        style={styles.iconBackButton}
                    />
                }
                data-testid="pagination-outline"
                isDisabled={isFirstPage}
                aria-label="right"
                borderRadius="0.375rem"
                onClick={onPreviousPage}
                style={styles.backButton}
            />

            <IconButton
                icon={
                    <Icon
                        as={MdChevronRight}
                        w="28px"
                        h="28px"
                        color={textColor}
                        style={styles.iconForwardButton}
                    />
                }
                data-testid="pagination-forward-button"
                borderRadius="0.375rem"
                isDisabled={isLastPage}
                aria-label="right"
                onClick={() => onNextPage()}
                style={styles.forwardButton}
            />
        </Stack>
    );
}
